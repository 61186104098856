import React from "react";
import {Collapse} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import PropTypes from 'prop-types';
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";

ErrorHelper.propTypes = {
    open: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string
}

ErrorHelper.defaultProps = {
    open: true
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#FFD6CC',
        padding: 15,
        borderRadius: 5,
        fontSize: 14,
        lineHeight: '135%'
    }
}))

export default function ErrorHelper({open, children, className}) {
    const classes = useStyles();

    return (
        <Collapse in={open}>
            <FormHelperText className={clsx(classes.root, className)}>
                {children}
            </FormHelperText>
        </Collapse>
    )
}
