import { action, makeAutoObservable, runInAction } from "mobx"
import { IProject, ProjectType } from "../services/projects/models";
import { createProject, getProjects, removeProject, removeUserFromProject } from "../services/projects";
import { ICreateProjectRequest } from "../services/projects/requestResponses";
import { createExperiment, getActiveExperiments, getDisabledExperiments } from "../services/experiments";
import { IExperimentSummary } from "../services/experiments/models";
import { ICreateExperimentRequest } from "../services/experiments/requestResponses";


class ProjectsStore {

    isLoading: boolean = false;
    list: IProject[] = [];
    current: IProject | null = null;
    activeExperiments: IExperimentSummary[] = [];
    disabledExperiments: IExperimentSummary[] = [];
    totalDisabled: number = 0;

    constructor() {
        makeAutoObservable(this)
    }

    @action
    setIsLoading(value: boolean) {
        this.isLoading = value;
    }


    @action
    setCurrent(project: IProject | null) {
        this.current = project;
    }
    @action
    setCurrentById(projectId: string) {
        this.current = this.list.find(item => item.id.toString() === projectId) || null;
    }



    async loadProjects(){
        const res = await getProjects({});
        if(!res.err){
            runInAction(() => {
                this.list = res.result.list.sort((a, b) => a.id - b.id);
                if(this.current) this.setCurrentById(this.current.id.toString());
            })
        }
    }

    async createProject(name: string){
        const params: ICreateProjectRequest = {
            name,
            type_id: ProjectType.API
        };

        const res = await createProject(params);
        await this.loadProjects();
        return res;
    }

    async removeUserFromCurrentProject(uid: string) {
        if(!this.current) return;
        const res = await removeUserFromProject({
            uid,
            projectId: this.current.id
        });
        await this.loadProjects();
        return res;
    }

    async removeProject() {
        if(!this.current) return;
        const res = await removeProject({
            projectId: this.current.id
        });
        await this.loadProjects();
        return res;
    }


    async loadActiveExperimentsForCurrentProject(){
        if(!this.current) return;
        const res = await getActiveExperiments(this.current.id, {});
        if(!res.err){
            runInAction(() => {
                this.activeExperiments = res.result.actives
                    .sort((a,b) => b.createAt - a.createAt);
            })
        }
    }

    async loadDisabledExperimentsForCurrentProject(options: {
        page: number,
        pageSize: number
    }){
        if(!this.current) return;
        const res = await getDisabledExperiments(this.current.id, options);
        if(!res.err){
            runInAction(() => {
                this.disabledExperiments = res.result.disabled
                    .sort((a,b) => b.createAt - a.createAt);
                this.totalDisabled = res.result.total;
            })
        }
    }

    async createNewExperiment(params: ICreateExperimentRequest){
        await createExperiment(params);
        await this.loadActiveExperimentsForCurrentProject();
    }


    async ensureProjectsLoaded(projectId: string){
        await this.loadProjects();
        this.setCurrentById(projectId);
        await this.loadActiveExperimentsForCurrentProject()
        await this.loadDisabledExperimentsForCurrentProject({page: 0, pageSize: 20});
    }

}

export default ProjectsStore;
