/**
 * <Table
 columns={[{
                        collapseIcon: true,
                        defaultCollapse: true
                    }, {
                        label: 'Date',
                        dataIndex: 'testdate',
                        render(v) {
                            if(!v)
                                return "01.01.1971";
                            let y = v.substr(0,4);
                            let m = v.substr(4,2);
                            let d = v.substr(6,2);
                            return d + '.' + m + '.' + y;
                        }
                    }, {
                        label: 'Name',
                        dataIndex: 'test'
                    }]}
 items={experiments.map(p => {
                        p['id'] = p.test;
                        p['collapse'] = () => (
                            <div>
                                Started {de(p.testdate)}<br/>
                            </div>
                        )
                        return p
                    })}/>
 */

import React, {useState} from "react";
import {Paper, Table, TableBody, TableContainer} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import PropTypes from 'prop-types';
import HeadCells from "./HeadCells";
import Row from './Row';

const useStyles = makeStyles(theme => ({
    table: {},
}));

_Table.defaultProps = {
    items: []
}

_Table.propTypes = {
    columns: PropTypes.array,
    rows: PropTypes.array,
    hideHeaders: false
}

export default function _Table({items, columns, collapse, rowClick, hideHeaders}) {
    const classes = useStyles();


    const [order, ] = useState();
    const [orderBy, ] = useState();

    const handleSetOrder = () => {}

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                {
                    !hideHeaders && <HeadCells order={order}
                                               orderBy={orderBy}
                                               onRequestSort={handleSetOrder}
                                               items={columns}/>
                }
                <TableBody>
                    {
                        items.map(item => <Row onClick={rowClick} item={item} columns={columns}>{item.collapse}</Row>)
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}
