import { action, makeAutoObservable } from "mobx"


class GeneralStore {

    alertInfo:{
        open: boolean,
        title: string
    } = {
        open: false,
        title: ""
    }

    constructor() {
        makeAutoObservable(this)
    }

    @action
    openAlert(title: string){
        this.alertInfo = {
            open: true,
            title
        }
    }

    @action
    closeAlert(){
        this.alertInfo = {
            open: false,
            title: ""
        }
    }

}

export default GeneralStore
