import React, {useState} from "react";

import {useHistory} from "react-router";
import {request} from "../../redux/actions/main";
import { Button, Dialog } from "components/index";
import { Typography } from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import { observer } from "mobx-react";
import { useStores } from "../../stores/context";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 16,
        paddingBottom: 24,
        paddingLeft: 24,
        paddingRight: 24
    },
    actions: {
        marginTop: 24,
        display: 'flex',
        justifyContent: 'space-between'
    }
}))

const Exit: React.FC<any> = ({children}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const {authStore} = useStores();



    const history = useHistory();

    const handlerExitApp = async () => {
        try {
            await request('accounts/exit').post();
            await authStore.logout();
            setOpen(false);
            history.replace('/login');
        } catch (e: any) {
            console.log(e);
        }
    }

    const handlerCancel = () => {
        setOpen(false);
    }

    const handlerOpenConfirm = () => {
        setOpen(true);
    }

    return (
        <>
            <Dialog open={open}
                    onClose={handlerCancel}>
                <div className={classes.root}>
                    <Typography>Are you sure you want to exit the application ?</Typography>

                    <div className={classes.actions}>
                        <Button color={'secondary'} onClick={handlerCancel}>Cancel</Button>
                        <Button onClick={handlerExitApp} variant={'contained'}>Confirm</Button>
                    </div>
                </div>
            </Dialog>

            <div onClick={handlerOpenConfirm}>
                {children}
            </div>
        </>
    )
}

export default observer(Exit);
