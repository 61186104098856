import React from "react";
import clsx from 'clsx';
import {makeStyles} from "@material-ui/styles";
import {Checkbox as CheckboxMui} from '@material-ui/core';
import { CheckboxProps } from "@material-ui/core/Checkbox/Checkbox";

const useStyles = makeStyles(theme => ({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    icon: {
        borderRadius: 4,
        width: 18,
        height: 18,
        border: '1px solid #EDEEF3',
        //boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        //backgroundColor: '#f5f8fa',
        //backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        border: '1px solid #546e7a00',
        backgroundColor: theme.palette.primary.main,
        '&:before': {
            display: 'block',
            width: 17,
            height: 17,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}));


const Checkbox = (props: CheckboxProps) => {
    const classes = useStyles();
    return (
        <CheckboxMui
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            {...props}
        />
    );
}


export default Checkbox;
