import configJSON from "../config.json";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { buildQueryParams } from "../redux/actions/main";
import RequestsController from "./RequestsController";

const requestController = new RequestsController();


// TODO: remove in the future
class FailedResponse {
    status: number
    data: any
    constructor(status: number, data: any) {
        this.status = status;
        this.data = data;
    }
}



const getFormXwww = (params: {[s: string]: any})  => {
    let formData = [];
    for (const property in params) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(params[property]);
        formData.push(encodedKey + "=" + encodedValue);
    }
    return formData.join("&");
}




interface IRequestOptions{
    requestId?: string,
    // progress in %
    onUploadProgress?: (progress: number) => void,
}

export type GeneralResponse<T> = {
    err: true
} | {
    err: false,
    result: T
}

export const request = <T extends {[s: string]: any}, R>(path: string, params: T, options: IRequestOptions) => {
    const url = path.search('http') !== -1 ? path : `${configJSON.api}/${path}`;


    const timeout = 60 * 10 * 1000;

    let requestIdUrl = options.requestId || url;

    requestController.cancelRequest(requestIdUrl);

    const CancelToken = axios.CancelToken.source();

    const config: AxiosRequestConfig = {
        headers: {
            //Accept: 'application/json',
            //authorization: localStorage.getItem('token') || '',

        },
        cancelToken: CancelToken.token,
        timeout: timeout,
        withCredentials: true,
        onUploadProgress: function(progressEvent) {
            if (options.onUploadProgress) {
                options.onUploadProgress(Math.round( (progressEvent.loaded * 100) / progressEvent.total ));
            }
        },
        validateStatus: (status) => {
            return true
        }
    }

    axios.defaults.timeout = timeout;
    axios.defaults.baseURL = configJSON.api;

    requestController.addRequest(requestIdUrl, CancelToken);

    return {
        async post(): Promise<AxiosResponse<R>> {
            try {
                // config.headers['Content-Type'] = 'multipart/form-data';

                const response = await axios.post(`${url}`, getFormXwww(params), config);

                requestController.removeRequest(requestIdUrl);

                return response;

            } catch (e: any) {
                console.log(e);
                requestController.removeRequest(requestIdUrl);


                // TODO: fix
                /*if (e.message == 'Network Error') {
                    window.store.dispatch(
                        setSnackbar('No connection to the server. Please wait...')
                    )
                }*/

                throw new FailedResponse(e.message, e.response);
            }
        },
        async delete(): Promise<AxiosResponse<R>> {
            try {
                let query = '';

                if (params) {
                    query = buildQueryParams(params)
                }

                const resp = await axios.delete(`${url}${query}`, config);

                requestController.removeRequest(requestIdUrl);

                return resp;
            } catch (e: any) {

                requestController.removeRequest(requestIdUrl);

                if (e.message === 'Network Error') {
                   /* window.store.dispatch(
                        setSnackbar('No connection to the server. Please wait...')
                    )*/
                }

                throw new FailedResponse(e.message, e.response.data);
            }
        },
        async get(): Promise<AxiosResponse<R>> {
            let query = '';

            if (params) {
                query = buildQueryParams(params)
            }

            const response = await axios.get(`${url}${query}`, config);
            requestController.removeRequest(requestIdUrl);
            return response;
        },
        async put(): Promise<AxiosResponse<R>> {
            try {
                const response = await axios.put(url, getFormXwww(params), config);

                requestController.removeRequest(requestIdUrl);

                return response;
            } catch (e: any) {
                requestController.removeRequest(requestIdUrl);

                if (e.message === 'Network Error') {
                    /*window.store.dispatch(
                        setSnackbar('No connection to the server. Please wait...')
                    )*/
                }
                throw new FailedResponse(e.status, e.response.data);

            }

        }
    }
}

