import React, { ChangeEvent } from "react";
import { IconButton as IconButtonMui, Tooltip } from "@material-ui/core";


interface Props {
    tooltip?: string,
    color?: 'inherit' | 'primary' | 'secondary' | 'default';
    onClick?: (e: ChangeEvent<any>) => void;
    className?: string,
    size?: "small"
}

const IconButton: React.FC<Props> = (props) => {
    const { tooltip, children, ...other } = props;

    return (
        <Tooltip aria-label={tooltip}
                 arrow={true}
                 title={tooltip || ""}>
            <IconButtonMui {...other}>
                {children}
            </IconButtonMui>
        </Tooltip>
    )
}

export default IconButton
