import React, {useEffect, lazy, Suspense} from "react";
import {LoadingScreen} from "../components";
import { BrowserRouter } from "react-router-dom";
import { useStores } from "../stores/context";
import { observer } from "mobx-react";

const Main = lazy(() => import("../layouts/Main"));
const Login = lazy(() => import("../layouts/Login"));

const Screens = ()  => {
    const {authStore,projectsStore} = useStores();


    useEffect(() => {
        authStore.getCurrentUser();
        projectsStore.loadProjects();
    }, [authStore, projectsStore])


    if (authStore.isLoading) {
        return <LoadingScreen/>
    }


    if (!authStore.isAuthorized) {
        return (
            <Suspense fallback={<LoadingScreen/>}>
                <Login/>
            </Suspense>
        )
    }

    return (
        <Suspense fallback={<LoadingScreen/>}>
            <BrowserRouter>
                <Main/>
            </BrowserRouter>
        </Suspense>
    )
}


export default observer(Screens)
