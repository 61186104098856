import React from "react";
import {makeStyles} from "@material-ui/styles";
import {
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Checkbox, TableCellProps,

} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableCell: {
        display: 'flex'
    },
    searchFieldContainer: {
        borderLeft: `solid 2px ${theme.palette.secondary.main}`,
        paddingLeft: theme.spacing(3)
    },
    notHover: {
        '&:hover': {
            cursor: 'default',
            color: '#263238'
        }
    }
}));
/*
EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number,
    onRequestSort: PropTypes.func,
    onSelectAllClick: PropTypes.func,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    headCells: PropTypes.array,
    checkbox: PropTypes.bool
};

EnhancedTableHead.defaultProps = {
    checkbox: false
}*/


export  interface IHeadCell extends TableCellProps{
    id: string,
    numeric: boolean,
    notSort?: boolean,
    disablePadding?: boolean,
    label: string
}
interface Props {
    numSelected?: number;
    onRequestSort?: (v: {order: 'asc' | 'desc', column: string}) => void;
    onSelectAllClick?: () => void;
    rowCount?: number;
    checkbox?: boolean;
    order: 'asc' | 'desc';
    orderBy: string;
    headCells: IHeadCell[];
}

const EnhancedTableHead = (props: Props) => {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        headCells,
        checkbox
    } = {
        numSelected: 0,
        rowCount: 0,
        ...props
    }

    const classes = useStyles();
    const {t} = useTranslation();

    const createSortHandler = (property: string) => () => {
        const isAsc = orderBy === property && order === 'asc';
        const _order = isAsc ? 'desc' : 'asc';

        if (onRequestSort) {
            onRequestSort({
                order: _order,
                column: property
            })
        }
    };

    const handleSelectAll = () => {
        if (onSelectAllClick) {
            onSelectAllClick();
        }
    }


    return (
        <TableHead>
            <TableRow>
                {
                    checkbox && (
                        <TableCell padding="checkbox">
                            <Checkbox
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={rowCount > 0 && numSelected === rowCount}
                                onChange={handleSelectAll}
                                inputProps={{'aria-label': 'select all desserts'}}
                            />
                        </TableCell>
                    )
                }

                {headCells.map((headCell) => {
                    let align: 'inherit' | 'left' | 'center' | 'right' | 'justify' = headCell.numeric ? 'right' : 'left';

                    if (headCell.align) {
                        align = headCell.align;
                    }

                    return (
                        <TableCell
                            key={`${headCell.id}`}
                            align={align}
                           /* className={clsx(headCell.onSearchChange && classes.tableCell)}*/
                            padding={headCell.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                className={clsx(headCell.notSort ? classes.notHover : '')}
                                hideSortIcon={Boolean(headCell.notSort && !(orderBy === headCell.id ? order : false))}
                            >
                                {t(headCell.label)}
                                {orderBy === headCell.id ?
                                    (
                                        <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    )
                                    : null}
                            </TableSortLabel>

                         {/*   {
                                headCell.onSearchChange && (
                                    <span className={classes.searchFieldContainer}>

                                        <SearchInput onChange={headCell.onSearchChange}
                                                     onSeek={headCell.onSeek ? headCell.onSeek : () => {}}
                                                     placeholder={t('Search')}/>
                                    </span>
                                )
                            }*/}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead;
