

export enum TestVariant {
    control = "Control",
    variant = "Variant"
}

export enum InAppFilterValue {
    All = 'All',
    Paying = 'paying',
    NotPaying = 'not_paying'
}

export enum ExperimentResult {
    success = "success",
    failed = 'fail',
    insufficient = "insufficient"
}

export interface IProgressValue{
    computed: Date,
    mean: number | null,
    stdDev: number | null,
    minUsersNumber: number | null
}

export interface IExperimentProgressValues{
    inapps: IProgressValue,
    impressions: IProgressValue,
}



export interface IExperiment {
    id: number,
    name: string,
    parameters: string
    project_id: number,
    description: string,
    create_at: string,
    is_active: 1 | 0,
    stop_at: string | null,
    percentage_hitting: number,
    is_user_by_date: 1 | 0,
    audience: string,
    progressValues: IExperimentProgressValues,
}

export interface IExperimentSummary{
    createAt: number,
    experimentId: number,
    stoppedAcceptingNewUsers: boolean,
    impressionsResult: ExperimentResult,
    revenuesResult: ExperimentResult,
    project: string,
    projectId: number,
    test: string,
    users: number,
    estimations: {
        inapps: number | null,
        impressions: number | null
    }
}

export interface IBootstrapTTestResult {
    result: ExperimentResult,
    amount: {
        mean1: number,
        mean2: number,
        average: number,
        amount1: number,
        amount2: number,
        amount2Sum: number,
        amount1Sum: number
    },
    p_value: number | null,
    testValue: number | null,
    confidence: [number, number],
    freedom: number,
    bins: {
        control: {
            values: number[],
            thresholds: number[]
        }
        variant: {
            values: number[],
            thresholds: number[]
        }
    }
}


export interface INumericMetric{
    category: string,
    data: IBootstrapTTestResult,
    name: string
}

export interface IConversionMetric{
    event: string,
    control: number,
    variant: number,
    p_value: number,
    improvement: number,
    result: ExperimentResult,
    category?: string
}




export interface IInAppSummary{
    amount: number,
    createAt: string,
    country?: string,
    acceptPurchase: 1 | 0,
    testPurchase: 1 | 0,
    subscriptionId?: number,
    purchaseLog?: string,
    appleTransactionId?: string,
    initialAmount?: number,
    initialCurrency?: string,
    orderId?: string,
    uid: string
}
