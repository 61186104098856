import { action, makeAutoObservable, runInAction } from "mobx"
import { IProfile } from "../services/auth/models";
import { getMe, signIn } from "../services/auth";
import { ISignInRequest } from "../services/auth/requestsResponses";

class AuthStore {
    isAuthorized: boolean = false;
    profile: IProfile | null = null;
    isError: boolean = false;
    isLoading: boolean = false;

    constructor() {
        makeAutoObservable(this)
    }

    @action
    setIsLoading(value: boolean) {
        this.isLoading = value;
    }


    async updateUser(){

    }

    async getCurrentUser() {
        this.setIsLoading(true);
        const response = await getMe({});
        if (!response.err) {
            runInAction(() => {
                this.isAuthorized = true;
                this.profile = {
                    ...response.result.profile,
                }
            });
        }else{
            await this.logout()
        }
        this.setIsLoading(false);
    }

    async login(params: ISignInRequest){
        this.setIsLoading(true);
        const response = await signIn(params);
        if(!response.err){
            runInAction(()=> {
                this.isAuthorized = true;
            });
            await this.getCurrentUser();
        }else{
            runInAction(()=> {
                this.isAuthorized = false;
            });
        }
        this.setIsLoading(false);
        return response.err;
    }

    async logout(){
        runInAction(() => {
            this.isAuthorized = false;
            this.profile = null;
            this.isError = false;
        })
    }
}

export default AuthStore
