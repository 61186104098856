import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { Tab as TabMui } from '@material-ui/core';
import PropTypes from 'prop-types';

export const StyledTab = withStyles((theme) => ({
    root: {
        minWidth: 80,
        textTransform: 'none',
        color: theme.palette.black,
        //@ts-ignore
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: 14,
        padding: 6,
        marginRight: theme.spacing(1),
        '&:focus': {
            opacity: 1,
        },
    },
}))((props) =>
    <TabMui disableRipple { ...props } />);

Tab.propTypes = {
    label: PropTypes.string
}

export default function Tab({ children, label, ...other }) {
    return (
        <StyledTab label={ label } { ...other } />
    );
}
