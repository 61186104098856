import React, { useState} from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {IconButton, Tooltip} from "@material-ui/core";
import {openAlert} from "../../redux/actions/alert";
import {useDispatch} from "react-redux";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'inline-block',
        background: theme.palette.selectionLight,
        padding: '7px 10px',
        borderRadius: 4,
        cursor: 'pointer',
        transition: '0.3s',
        color: '#0030a5'
    },
    content: {
        display: 'flex',
        //justifyContent: 'space-between',
        alignItems: 'center'
    },
    text: {
        color: 'inherit'
    },
    icon: {
        color: theme.palette.primaryColor
    },
    center: {
        display: 'flex',
        alignItems: 'center',
    },
    end: {
        display: "flex",
        justifyContent: 'flex-end'
    },
    input: {
        display: "none"
    },
    snack: {
        top: 0,
        right: '45vw',
        position: 'fixed'
    }
}));


interface Props{
    className?: string,
    classNameBtn?: string,
    classNameIcon?: string
}
const Code: React.FC<Props> = (props) => {

    const {children, className, classNameIcon, classNameBtn} = props;
    const [show, set] = useState(false);

    const classes = useStyles();
    const dispatch = useDispatch();

    const handleMouseMode = () => {
        if (!show) {
            set(true);
        }

    }

    const handleMouseLeave = () => {
        if (show) {
            set(false);
        }
    }

    const handleCopyToClipBoard = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(
            openAlert('Copied to clipboard')
        )

        const el = document.createElement('textarea');
        el.value = ((typeof children === 'string') || (typeof children === 'number') ) ? `${children}` : "";
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    return (
        <div className={clsx(classes.root, className)}
             onMouseMove={handleMouseMode}
             onClick={handleCopyToClipBoard}
             onMouseLeave={handleMouseLeave}>
            <div className={classes.content}>
                <Typography className={classes.text}>{children}</Typography>

                <Tooltip title={'copy'} arrow>
                    <IconButton onClick={handleCopyToClipBoard} className={classNameBtn}>
                        <i className={clsx("fal fa-copy", classes.icon, classNameIcon)}/>
                    </IconButton>
                </Tooltip>
            </div>

        </div>
    )
}

export default Code;
