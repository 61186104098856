export enum ProjectType{
    API = 2,
    GOOGLE= 1
}


export interface IProject{
    auth_provider_x509_cert_url: null | string;
    auth_url: null | string;
    client_email: null | string;
    client_id: null | string;
    client_x509_cert_url: null | string;
    guid: string;
    id: number;
    name: string;
    postfix: string;
    private_key: string;
    private_key_id: string;
    project_id: string;
    token_url: string;
    type: null;
    type_id: ProjectType;
    userid: number;
}


export interface IProjectLogItem{
    createAt: Date,
    status: number,
    url: string,
    projectId: number,
    id: number
}


export interface IServiceAccounts{
    google_service_account: string | null,
    apple_service_account: string | null,
    antifraud: 0 | 1,
    includeTestPurchases: 0 | 1
}


export interface IInAppSummary{
    amount: number,
    createAt: string,
    acceptPurchase: 1 | 0,
    testPurchase: 1 | 0,
    subscriptionId?: number,
    purchaseLog?: string,
    appleTransactionId?: string,
    initialAmount?: number,
    initialCurrency?: string,
    googleOrderId?: string,
    uid: string
}
