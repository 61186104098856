import React from "react";
import { Button as MuiButton, ButtonProps, CircularProgress, Typography } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";




const UiButton = withStyles((theme) => ({
    root: {
        boxShadow: 'none',
        padding: '9.5px 16px 9.5px 16px',
        height: 36,
        //border: '4px solid #e5e7f600',
        borderRadius: 4,
        '&.MuiButton-startIcon': {
            color: theme.palette.primary.main,
            marginRight: 0
        },
        '&.MuiButton-sizeLarge': {
            padding: '14px 18px 14px 18px',
        },
        '&.MuiButton-sizeSmall': {
            padding: '4px 10px 4px 10px',
        },
        '&.MuiButton-iconSizeSmall': { //todo fix
            '&.MuiButton-startIcon': {
                marginLeft: '-4px'
            },
        },
        '&.MuiButton-containedPrimary': {
            backgroundColor: 'linear-gradient(105.99deg, #6B7FE3 0.55%, #5C6FCE 49.03%, #6B7FE3 100%);'
        },
        '&.MuiButton-containedSecondary': {
            color: theme.palette.primary.main,
            '&:hover': {
                boxShadow: 'none',
                backgroundColor: '#eff1fd'
            },
            '&.Mui-focusVisible': {
                boxShadow: 'none',
                backgroundColor: '#EFF1FD'
            }
        },
        '&.Mui-disabled': {
            color: '#000'
        }
    },

}))(MuiButton)

const useStyles = makeStyles(theme => ({
    text: {
        color: '#fff',
        textTransform: 'none'
    },
    disabledText: {
        color: theme.palette.dimGrey
    },
    secondaryColor: {
        color: theme.palette.primaryColor
    },
    outlinedText: {
        color: theme.palette.primaryColor
    },
    danger: {
        backgroundColor: `${theme.palette.pink} !important`,
        color: `${theme.palette.red}`,
        "& .MuiTouchRipple-root span":{
            backgroundColor: `${theme.palette.red}`,
            opacity: 0.3,
        },
    }
}))

interface Props extends ButtonProps{
    loading?: boolean,
    className?: string,
    buttonType?: 'success' | 'warning' | 'danger' | 'disabled'| 'submit',
    color?: 'primary' | 'secondary',
    variant?: 'outlined' | 'contained',
    size?: 'medium' | 'large' | 'small',
    tooltip?: string,
    disabled?: boolean,
    fullWidth?: boolean,
    onClick?: () => void;
    startIcon?: React.ReactElement,
    removeStopPropagation?: boolean
}
const Button: React.FC<Props> = (props) => {

    const classes = useStyles();

    const disabled = props.loading || props.disabled;

    const isSecondary = props.color === 'secondary';

    const isOutLined = props.variant === 'outlined';

    let classNameBtn = clsx(props.className);

    if (props.buttonType === 'danger') {
        classNameBtn = clsx(classNameBtn, classes.danger)
    }

    return (
        <UiButton disabled={ disabled }
                  disableFocusRipple={ isSecondary }
                  color={ props.color || 'primary' }
                  variant={props.variant || 'contained'}
                  /*type={props.type}*/
                  size={props.size}
                  className={ classNameBtn }
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      !props.removeStopPropagation && e.stopPropagation();
                      props.onClick && props.onClick()
                  }}
                  startIcon={props.startIcon}
                  fullWidth={props.fullWidth}
        >
            {
                props.loading
                    ? <CircularProgress color={ 'secondary' } size={ 28 }/>
                    : (
                        <Typography className={ clsx(
                            classes.text,
                            isSecondary ? classes.secondaryColor : '',
                            disabled ? classes.disabledText : '',
                            isOutLined ? classes.outlinedText : '',
                            props.buttonType === 'danger' ? classes.danger : ''
                        ) }>
                            { props.children }
                        </Typography>
                    )
            }
        </UiButton>
    )
}

export default Button;
