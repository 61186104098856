import React, {Component} from 'react'
import './App.css';
import {ThemeProvider} from '@material-ui/styles';
import abtestreal from './themes/abtestreal';

import {Provider} from 'react-redux';
import {store} from "./utils/store";
import "./utils/DatePrototype";
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from "@date-io/date-fns";

import './libs/fontawesome-pro-5.9.0-web/css/all.css';


import {Screens} from "./screens";


class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={abtestreal}>
                        <Screens />
                    </ThemeProvider>
                </MuiPickersUtilsProvider>

            </Provider>
        );
    }

}

export default App;
