import {SET_PROJECTS} from "../actions/projects";

const initialState = {
    items: []
};

export default function projects(state = initialState, action) {
    switch (action.type) {
        case SET_PROJECTS:
            return {
                items: action.data
            }
        default:
            return state;
    }
}
