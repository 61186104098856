import { makeAutoObservable } from "mobx";
import AuthStore from "./auth";
import ProjectsStore from "./projects";
import ExperimentsStore from "../screens/AsyncViewExperiment/context/experiments";
import GeneralStore from "./general";


const authStore = new AuthStore();
const experimentsStore = new ExperimentsStore();
const projectsStore = new ProjectsStore();
const generalStore = new GeneralStore();

interface IStores {
    authStore: AuthStore;
    projectsStore: ProjectsStore;
    experimentsStore: ExperimentsStore;
    generalStore: GeneralStore;
}

export class RootStore {
    authStore: AuthStore;
    projectsStore: ProjectsStore;
/*
    experimentsStore:ExperimentsStore ;
*/
    generalStore: GeneralStore;

    constructor(stores: IStores) {
        makeAutoObservable(this);
        this.projectsStore = stores.projectsStore;
        /*this.experimentsStore = stores.experimentsStore;*/
        this.authStore = stores.authStore;
        this.generalStore = stores.generalStore;

    }
}

export const stores: IStores = {
    projectsStore,
    authStore,
    experimentsStore,
    generalStore
};

const rootStore = new RootStore(stores);
export default rootStore
