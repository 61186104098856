import React, {useEffect} from "react";
import {useSpring, animated} from "react-spring";


const hideStyle = (spring: number) => ({
    transform: `translate(0, ${spring}px)`,
    height: 0,
    opacity: 0
});

const showStyle = {
    opacity: 1,
    height: 'auto',
    transform: 'translate(0, 0)'
}

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>{
    spring?: number,
    show?: boolean,
    rerenderPoint?: any
}

const SpringSlideUp: React.FC<Props> = (props) => {

    const t = {
        spring: 100,
        show: true,
        ...props,
    }
    if(!t.spring) t.spring = 100;
    if(t.show === undefined) t.show = true;




    const [style, set] = useSpring<{
        opacity: number,
        height: string | number,
        transform: string
    }>(() => hideStyle(t.spring))

    const {show, spring, rerenderPoint, ...other} = t;
    useEffect(() => {
        set(show ? showStyle : hideStyle(spring));

    }, [show, spring, set]);

    useEffect(() => {
        set(hideStyle(spring));

        setTimeout(() => {
            set(showStyle);
        }, 500);


    }, [rerenderPoint, set, spring]);

    return (
        //@ts-ignore
        <animated.div style={style} {...other} >
            {
                t.show && (t.children || [])
            }
        </animated.div>
    )
}

export default SpringSlideUp;
