import {
    ICreateExperimentRequest,
    ICreateExperimentResponse,
    IGetActiveExperimentsRequest,
    IGetActiveExperimentsResponse,
    IGetCumulativeImpressionsRequest, IGetCumulativeImpressionsResponse,
    IGetCumulativeInnapsRequest,
    IGetCumulativeInnapsResponse,
    IGetDisabledExperimentsRequest,
    IGetDisabledExperimentsResponse,
    IGetExperimentRequest,
    IGetExperimentResponse,
    IGetFunnelRequest,
    IGetFunnelResponse, IGetFunnelTypesRequest, IGetFunnelTypesResponse,
    IGetImpressionsRequest,
    IGetImpressionsResponse,
    IGetInappsByRangeRequest,
    IGetInappsByRangeResponse,
    IGetInappsTotalByRangeRequest,
    IGetInappsTotalByRangeResponse,
    IGetMetricRequest,
    IGetMetricResponse,
    IGetRevenuesRequest,
    IGetRevenuesResponse,
    IGetTopFunnelRequest,
    IGetTopFunnelResponse
} from "./requestResponses";
import { GeneralResponse, request } from "../../api";


export const getActiveExperiments = async (projectId: number, params: IGetActiveExperimentsRequest): Promise<GeneralResponse<IGetActiveExperimentsResponse>>  => {
    const res =  await request<IGetActiveExperimentsRequest, IGetActiveExperimentsResponse>(
        `projects/${projectId}/dashboard/actives`, params, {}).get();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}

export const getDisabledExperiments = async (projectId: number, params: IGetDisabledExperimentsRequest): Promise<GeneralResponse<IGetDisabledExperimentsResponse>>  => {
    const res =  await request<IGetDisabledExperimentsRequest, IGetDisabledExperimentsResponse>(
        `projects/${projectId}/dashboard/disabled`, params, {}).get();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}




export const createExperiment = async (params: ICreateExperimentRequest): Promise<GeneralResponse<ICreateExperimentResponse>>  => {
    const res =  await request<ICreateExperimentRequest, ICreateExperimentResponse>(
        `experiment`, params, {}).post();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}



export const getAllActiveExperiments = async (params: IGetActiveExperimentsRequest): Promise<GeneralResponse<IGetActiveExperimentsResponse>>  => {
    const res =  await request<IGetActiveExperimentsRequest, IGetActiveExperimentsResponse>(
        `projects/account/active`, params, {}).get();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}



export const getExperiment = async (experimentId: string): Promise<GeneralResponse<IGetExperimentResponse>> => {
    const res =  await request<IGetExperimentRequest, IGetExperimentResponse>(
        `experiment/${experimentId}`, {  }, {}).get();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}




export const getTopFunnel = async (experimentId: string, funnelType: string): Promise<GeneralResponse<IGetTopFunnelResponse>> => {
    const res =  await request<IGetTopFunnelRequest, IGetTopFunnelResponse>(
        `experiment/${experimentId}/topFunnel`, {funnelType}, {}).get();

    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}

export const getFunnelTypes = async (experimentId: string): Promise<GeneralResponse<IGetFunnelTypesResponse>> => {
    const res =  await request<IGetFunnelTypesRequest, IGetFunnelTypesResponse>(
        `experiment/${experimentId}/funnelTypes`, {}, {}).get();

    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}

export const getRevenues = async (experimentId: string, params: IGetRevenuesRequest): Promise<GeneralResponse<IGetRevenuesResponse>> => {
    const res =  await request<IGetRevenuesRequest, IGetRevenuesResponse>(
        `experiment/${experimentId}/revenues`, params, {}).get();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}

export const getImpressions = async (experimentId: string, params: IGetImpressionsRequest): Promise<GeneralResponse<IGetImpressionsResponse>> => {
    const res =  await request<IGetImpressionsRequest, IGetImpressionsResponse>(
        `experiment/${experimentId}/impressions`, params, {}).get();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}


export const getFunnel = async (experimentId: string, params: IGetFunnelRequest): Promise<GeneralResponse<IGetFunnelResponse>> => {
    const res =  await request<IGetFunnelRequest, IGetFunnelResponse>(
        `experiment/${experimentId}/funnel`, params, {}).get();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}


export const getMetrics = async (experimentId: string, params: IGetMetricRequest): Promise<GeneralResponse<IGetMetricResponse>> => {
    const res =  await request<IGetMetricRequest, IGetMetricResponse>(
        `experiment/${experimentId}/metrics`, params, {}).get();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}



export const getInappsByRange = async (experimentId: string, params: IGetInappsByRangeRequest): Promise<GeneralResponse<IGetInappsByRangeResponse>> => {
    const res =  await request<IGetInappsByRangeRequest, IGetInappsByRangeResponse>(
        `experiment/${experimentId}/inapps`, params, {}).post();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}


export const getInappsTotalByRange = async (experimentId: string, params: IGetInappsTotalByRangeRequest):
    Promise<GeneralResponse<IGetInappsTotalByRangeResponse>> => {
    const res =  await request<IGetInappsTotalByRangeRequest, IGetInappsTotalByRangeResponse>(
        `experiment/${experimentId}/inapps/total`, params, {}).post();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}



export const getCumulativeInapps = async (experimentId: string, params: IGetCumulativeInnapsRequest): Promise<GeneralResponse<IGetCumulativeInnapsResponse>> => {
    const res =  await request<IGetCumulativeInnapsRequest, IGetCumulativeInnapsResponse>(
        `experiment/${experimentId}/metric/cumulative/inapps`, params, {}).get();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}

export const getCumulativeImpressions = async (experimentId: string, params: IGetCumulativeImpressionsRequest): Promise<GeneralResponse<IGetCumulativeImpressionsResponse>> => {
    const res =  await request<IGetCumulativeImpressionsRequest, IGetCumulativeImpressionsResponse>(
        `experiment/${experimentId}/metric/cumulative/impressions`, params, {}).get();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}
