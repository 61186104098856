class Utils {
    static c(x, c){
        if(c !== "")
        {
            let r = c + x;
            if(r.indexOf(c + "-") > -1)
                r = r.replace(c + "-", "-" + c);
            return r;
        }
        return x;
    }

    static r0(x){
        return Math.round(x);
    }
    static r1(x){
        return Utils.r(x, 1);
    }
    static r2(x){
        return Utils.r(x, 2);
    }
    static r3(x){
        return Utils.r(x, 3);
    }
    static r4(x){
        return Utils.r(x, 4);
    }
    static r5(x){
        return Utils.r(x, 5);
    }
    static r6(x){
        return Utils.r(x, 6);
    }
    static r(x, base){
        return Number.parseFloat(x).toFixed(base);
    }
    static d(datestr) {
        if(!datestr)
            return new Date();
        let y = datestr.substr(0,4);
        let m = datestr.substr(4,2);
        let d = datestr.substr(6,2);
        return new Date(y,m*1-1,d);
    };

    static df(datestr) {
        if(!datestr)
            return "01.01.1971";
        let y = datestr.substr(0,4);
        let m = datestr.substr(4,2);
        let d = datestr.substr(6,2);
        return d + '.' + m + '.' + y;
    };
    static de(datestr) {
        let m = Utils.d(datestr);
        let dd = Math.floor((new Date().getTime() - m.getTime()) / 1000.0 / 60.0 / 60.0 / 24.0);

        if(dd > 34)
            return "More than Month ago";
        else {
            if(dd > 7)
                dd = dd - dd % 7;
            switch (dd) {
                case 0:
                    return 'Today';
                case 1:
                    return '1 Day ago';
                case 2:
                    return '2 Days ago';
                case 3:
                    return '3 Days ago';
                case 4:
                    return '4 Days ago';
                case 5:
                    return '5 Days ago';
                case 6:
                    return '6 Days ago';
                case 7:
                    return '1 Week ago';
                case 14:
                    return '2 Week ago';
                case 21:
                    return '3 Weeks ago';
                case 28:
                    return 'Month ago';
                default:
                    return Utils.df(datestr);
            }
        }
    };
}

export default Utils;