import React, { useState} from "react";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    paginationWidgetContainer: {
        display: 'flex',
        marginTop: 10,
        fontFamily: 'Inter,Ubuntu,Roboto,sans-serif;',
        fontWeight: 500
    },
    item: {
        background: "white",
        padding: "8px 12px",
        border: '1px solid',
        borderColor: `${theme.palette.border}`,
        color: `${theme.palette.primaryColor}`,
        borderRadius: 4,
        marginRight: 5,
        cursor: "pointer",
        "&:hover": {}
    },
    active: {
        background: `${theme.palette.selectionLight}`,
        border: "none"
    }

}));


const PageNumberItem = (props) => {
    const {isActive, next, pageNumber} = props;
    const styles = useStyles();
    const classes = [styles.item];
    if (isActive) {
        classes.push(styles.active);
    }
    return <div className={classes.join(" ")} onClick={next}>
        {pageNumber + 1}
    </div>
}

const LeftArrow = (props) => {
    const {next} = props;
    const styles = useStyles();
    const classes = [styles.item];

    return <div className={classes.join(" ")} onClick={next}>
        <i className={"far fa-chevron-left"}/>
    </div>
}

const RightArrow = (props) => {
    const {next} = props;
    const styles = useStyles();
    const classes = [styles.item];

    return <div className={classes.join(" ")} onClick={next}>
        <i className={"far fa-chevron-right"}/>
    </div>
}


const PaginationWidget = (props) => {
    const {pageSize, total, setCurrentPage, currentPage} = props;
    const [visibleSince, setVisibleSince] = useState(0);
    const visible = 5;

    const styles = useStyles();

    const pagesNumber = Math.ceil(total / pageSize);
    const pages = Array.from({length: pagesNumber}, (_, i) => i);

    return <div className={styles.paginationWidgetContainer}>
        {visibleSince >= visible && <LeftArrow
            next={() => {
                const newVisibleSince = visibleSince - visible;
                setVisibleSince(Math.max(newVisibleSince, 0))
            }}
        />}
        {pages.slice(visibleSince, visible + visibleSince).map((item) => {
            return <PageNumberItem
                key={item}
                isActive={item === currentPage}
                next={() => setCurrentPage(item)}
                pageNumber={item}
            />
        })
        }
        {
            pagesNumber > visibleSince + visible && <RightArrow
                next={() => {
                    const newVisibleSince = visible + visibleSince;
                    setVisibleSince(Math.min(newVisibleSince, pagesNumber))
                }}
            />
        }
    </div>
}


export default PaginationWidget;
