import React, {useEffect} from "react";
import {TableRow, TableCell, IconButton, Collapse, Box} from "@material-ui/core";
import {KeyboardArrowDown, KeyboardArrowUp} from "@material-ui/icons";
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";
const useRowStyles = makeStyles(theme => ({
    root: {
        // '& > *': {
        //     borderBottom: 'unset',
        // },

    },
    hover: {
        transition: '0.3s',
        cursor: "pointer",
        '&:hover': {
            backgroundColor: theme.palette.background.default
        }
    }
}));

export default function Row({item, columns, children, onClick}) {
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    useEffect(() => {
        const findCollapseColumn = columns.find(c => c.defaultCollapse === true);

        if (findCollapseColumn) {
            setOpen(true);
        }
    }, [columns]);

    const handleClick = e => {
        if (onClick) {
            onClick(e, item);
        }
    }

    return (
        <React.Fragment>
            <TableRow key={item.id} className={clsx(classes.root, onClick ? classes.hover : '')} onClick={handleClick}>
                {
                    columns.map(c => {
                        if (c.collapseIcon) {
                            return (
                                <TableCell>
                                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                    </IconButton>
                                </TableCell>
                            )
                        }

                        return (
                            <>
                                <TableCell component="th"
                                           scope="row">{c.render ? c.render(item[c.dataIndex]) : item[c.dataIndex]}</TableCell>
                            </>
                        )
                    })
                }
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            {children}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
