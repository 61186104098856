import React from "react";
import {makeStyles} from "@material-ui/styles";
import {useDispatch} from "react-redux";
import {openAlert} from "../../redux/actions/alert";


const useStyles = makeStyles(theme => ({
    root: {
        cursor: 'pointer',
        transition: '0.3s',
        color: '#0030a5',
        '&:hover': {
            color: '#001d66',
        }
    }
}));


export default function Code({children}) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const handleCopyToClipBoard = e => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(
            openAlert('Copied to clipboard')
        )

        const el = document.createElement('textarea');
        el.value = children;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    return (
        <span className={classes.root}
             onClick={handleCopyToClipBoard}>
          {children}
        </span>
    )
}
