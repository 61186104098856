import {OPEN_ALERT, CLOSE_ALERT} from "../actions/alert";

const initialState = {
    open: false,
    message: ''
};

export default function test(state = initialState, action) {
    switch (action.type) {
        case OPEN_ALERT:
            return {
                open: true,
                message: action.data
            }
        case CLOSE_ALERT: {
            return {
                open: false,
                message: ''
            }
        }
        default:
            return state;
    }
}
