
const twoDigits = (d: number) => {
    if(0 <= d && d < 10) return "0" + d.toString();
    if(-10 < d && d < 0) return "-0" + (-1*d).toString();
    return d.toString();
}



Date.prototype.toFullDateTimeString = function() {
    return this.getUTCFullYear() + "-" + twoDigits(1 + this.getUTCMonth()) + "-" + twoDigits(this.getUTCDate()) + " " + twoDigits(this.getUTCHours()) + ":" + twoDigits(this.getUTCMinutes()) + ":" + twoDigits(this.getUTCSeconds());
};

Date.prototype.his = function () {
    return `${twoDigits(this.getUTCHours())}:${twoDigits(this.getMinutes())}:${twoDigits(this.getSeconds())}`
}

Date.prototype.formatToHoursAndMinutes = function () {
    return `${twoDigits(this.getUTCHours())}:${twoDigits(this.getMinutes())}`
}
Date.prototype.ddhis = function () {
    const ts = this.getTime() / 1000;

    const oneDay = 86400;

    const dd = parseInt(Math.trunc(ts / oneDay).toFixed(0));

    const time = ts - (Number(dd) * oneDay);

    const dayStr = dd > 0 ? `${dd} day ` : '';

    return `${dayStr}${new Date(time * 1000).his()}`;
}

Date.prototype.getFullDateStr = function () {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
        "July", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    return `${this.getDate()} ${monthNames[this.getUTCMonth()]} ${this.getFullYear()}`
}

Date.prototype.getAlongToDayStr = function () {
    const toDayTs = new Date().getTime() / 1000;
    const ts = this.getTime() / 1000;

    const oneDay = 86400;

    const oneWeek = oneDay * 7;

    const difference = toDayTs - ts;

    const weeks = Math.floor(difference / oneWeek);

    if (weeks === 1) {
        return `${Math.floor(weeks)} week ago`
    }

    if (weeks >= 2) {
        return `${weeks.toFixed(0)} weeks ago`
    }

    const days = difference / oneDay;

    if (days >= 1) {
        return `${days.toFixed(0)} day ago`
    }

    return 'today'
}

export {};
