export const OPEN_ALERT = 'OPEN_ALERT';

export function openAlert(data) {
    return {
        type: OPEN_ALERT,
        data: data
    }
}

export const CLOSE_ALERT = 'CLOSE_ALERT';

export function closeAlert() {
    return {
        type: CLOSE_ALERT
    }
}
