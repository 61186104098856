import React from "react";
import {Fade, LinearProgress} from "@material-ui/core";
import PropTypes from 'prop-types';
import {makeStyles} from "@material-ui/styles";

ScreenLoader.propTypes = {
    loading: PropTypes.bool
}

ScreenLoader.defaultProps = {
    loading: true
}

const useStlyes = makeStyles(theme => ({
    linear: {
        paddingTop: 5
    }
}));

export default function ScreenLoader({loading}) {
    const classes = useStlyes();

    return (
        <Fade in={loading}>
            <LinearProgress className={classes.linear} color={'secondary'} />
        </Fade>
    )
}