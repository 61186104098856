import configJSON from "../../config.json"
import RequestsController from "../../utils/RequestsController";
import axios from 'axios';
import {setSnackbar} from "./snackbar";


const requestController = new RequestsController();

const failResponse = function (status, data) {
    this.status = status;
    this.data = data;
}


const getHeaders = () => {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append("Authorization", localStorage.getItem('token'));
    return headers;
}

const checkStatus = (status) => {
    const accessStatus = [200];
    return accessStatus.includes(status);
}

const getForm = params => {
    let formData = new FormData();
    Object.keys(params).forEach(key => formData.append(key, params[key]));
    return formData;
}

const getFormXwww = params => {
    let formData = [];
    for (var property in params) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(params[property]);
        formData.push(encodedKey + "=" + encodedValue);
    }
    return formData.join("&");
}

export const buildQueryParams = params => {
    const stringParams = Object.keys(params).filter(key => {
        const value = params[key];
        if ((typeof value === 'object') && (value.length === 0)) {
            return false;
        }
        return Boolean(value) || value === 0;
    }).map(key => `${key}=${params[key]}`).join('&');
    return stringParams.length !== 0 ? `?${stringParams}` : '';
}


export const PERMISSION_DENIED = 'PERMISSION_DENIED';
const permissionDenied = () => {
    window.store.dispatch({
        type: PERMISSION_DENIED
    })
}

export const SNACKBAR_RESET = 'SNACKBAR_RESET';



export function request(path, params, onUploadProgress, requestId) {
    const url = path.search('http') !== -1 ? path : `${configJSON.api}/${path}`;

    const timeout = 60 * 10 * 1000;

    let requestIdUrl = requestId || url;

    requestController.cancelRequest(requestIdUrl);

    const CancelToken = axios.CancelToken.source();

    const config = {
        headers: {
            //Accept: 'application/json',
            //authorization: localStorage.getItem('token') || '',

        },
        cancelToken: CancelToken.token,
        timeout: timeout,
        withCredentials: true,
        onUploadProgress: function(progressEvent) {
            if (onUploadProgress) {
                onUploadProgress(Math.round( (progressEvent.loaded * 100) / progressEvent.total ));
            }
        }
    }

    axios.defaults.timeout = timeout;
    axios.defaults.baseURL = configJSON.api;

    requestController.addRequest(requestIdUrl, CancelToken);

    return {
        async post() {
            try {
               // config.headers['Content-Type'] = 'multipart/form-data';

                const {data} = await axios.post(`${url}`, getFormXwww(params), config);

                requestController.removeRequest(requestIdUrl);

                return data;

            } catch (e) {

                requestController.removeRequest(requestIdUrl);


                // TODO: fix
                /*if (e.message == 'Network Error') {
                    window.store.dispatch(
                        setSnackbar('No connection to the server. Please wait...')
                    )
                }*/

                throw new failResponse(e.message, e.response.data);
            }
        },
        async delete() {
            try {
                let query = '';

                if (params) {
                    query = buildQueryParams(params)
                }

                const resp = await axios.delete(`${url}${query}`, config);

                requestController.removeRequest(requestIdUrl);

                return resp;
            } catch (e) {

                requestController.removeRequest(requestIdUrl);

                if (e.message === 'Network Error') {
                    window.store.dispatch(
                        setSnackbar('No connection to the server. Please wait...')
                    )
                }

                throw new failResponse(e.message, e.response.data);
            }
        },
        async get() {
            try {
                let query = '';

                if (params) {
                    query = buildQueryParams(params)
                }

                const response = await axios.get(`${url}${query}`, config);
                requestController.removeRequest(requestIdUrl);
                return response.data;
            } catch (e) {
                if (e.message === 'Network Error') {
                   /* window.store.dispatch(
                        setSnackbar('No connection to the server. Please wait...')
                    )*/
                }

                requestController.removeRequest(requestIdUrl);

                throw new failResponse(e.status, e.response.data);
            }
        },
        async put() {
            try {
                const {data} = await axios.put(`${path.search('http') !== -1 ? path : url}`, getFormXwww(params), config);

                requestController.removeRequest(requestIdUrl);

                return data;
            } catch (e) {

                requestController.removeRequest(requestIdUrl);

                if (e.message === 'Network Error') {
                    /*window.store.dispatch(
                        setSnackbar('No connection to the server. Please wait...')
                    )*/
                }
            }
        }
    }
}
