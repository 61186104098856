import {SET_TEST} from "../actions/test";

const initialState = {

};

export default function test(state = initialState, action) {
    switch (action.type) {
        case SET_TEST:
            return action.data
        default:
            return state;
    }
}
