export const SET_TESTS = 'SET_TESTS';
export const SET_ACTIVES = 'SET_ACTIVES';
export const SET_DISABLED = 'SET_DISABLED';

export function setTests(data) {
    return {
        type: SET_TESTS,
        data: data
    }
}

export function setActives(data) {
    return {
        type: SET_ACTIVES,
        data: data
    }
}

export function setDisabled(data) {
    return {
        type: SET_DISABLED,
        data: data
    }
}

