import React from "react";
import { makeStyles } from "@material-ui/styles";
import { TableBody, TableContainer, TableRow, Table, Typography, TableCell, Paper } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        marginTop: 8
    },
    codeError: {
        color: '#rr'
    }
}));

HttpCodeStatusSummary.defaultProps = {
    rows: []
}

export default function HttpCodeStatusSummary({ rows }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography variant={'h4'}>HTTP status codes</Typography>
            </div>

            <div className={classes.content}>
                <TableContainer component={Paper} elevation={0}>
                    <Table>
                        <TableBody>
                            {
                                rows.map(r => (
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant={'body2'} color={r.type === 'error' ? 'error' : r.type === 'success' ? 'primary' : 'initial'}>
                                                {r.code}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={'body2'}>
                                                {r.descr}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

        </div>
    )
}