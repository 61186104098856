import { CancelTokenSource } from "axios";

class RequestsController {
    requests: {url: string, cancelToken: CancelTokenSource}[] = [];
    constructor() {
        this.requests = [];
    }

    cancelRequest(url: string) {
        this.requests = this.requests.filter(r => {
            if (r.url === url) {
                r.cancelToken.cancel();

                return false;
            }

            return true;
        })
    }

    addRequest(url: string, cancelToken: CancelTokenSource) {
        this.requests.push({
            url,
            cancelToken
        })
    }

    removeRequest(url: string) {
        this.requests = this.requests.filter(r => r.url !== url)
    }
}

export default RequestsController;
