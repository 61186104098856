import React from "react";
import {withStyles} from "@material-ui/styles";
import {Slider} from "@material-ui/core";


const UsersCountSlider = withStyles(theme => ({
    root: {
        height: 4,
    },
    thumb: {
        display: 'none'
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 6,
        borderRadius: 3,
        //borderTopRightRadius: 0,
        //borderBottomRightRadius: 0,
        color: theme.palette.green
    },
    rail: {
        height: 6,
        borderRadius: 3,
        opacity: 1,
        color: theme.palette.border
    },
}))(Slider);

export default UsersCountSlider;
