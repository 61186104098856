import { withStyles } from "@material-ui/styles";
import { InputBase } from "@material-ui/core";

export const BootstrapInput = withStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        'label + &': {
            marginTop: theme.spacing(3),
        },
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        border: `1px solid ${theme.palette.border}`,
        '&.Mui-error': {
            border: `1px solid ${ theme.palette.red }`,
            borderRadius: 4
        },
        '&.MuiInputBase-adornedEnd': {
            paddingRight: 10
        },
        '&::placeholder': {
            color: theme.palette.dimGrey
        }
    },
    input: {
        position: 'relative',
        flex: 1,
        fontSize: 14,
        width: 'auto',
        padding: '11px 10px',
        //padding: '6px 10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            //boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
        '&:disabled': {
            backgroundColor: theme.palette.disabled
        },
        '&.Mui-error': {
            border: `1px solid ${ theme.palette.red }`,
            borderRadius: 4
        }
    },
}))(InputBase);