import React, { useEffect, useMemo, useState } from "react";
import { FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { request } from "../../redux/actions/main";
import { Select } from "components/index";
import { useExperimentContext } from "../../screens/ViewExperiment/context/Experiment.context";

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%'
    },
    subText: {
        fontStyle: 'italic'
    },
    menuItem: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}))

const defaultCountries = [{
    value: 'All',
    text: 'All'
}];

export default function ExperimentCountrySelect({ experimentId, onSelect }) {
    const {filters} = useExperimentContext();
    const [value, setValue] = useState(filters.country);
    const [items, setItems] = useState(filters.country.split(',').map(v => ({
        value: v,
        text: v
    })));

    const classes = useStyles();

    

    useMemo(() => {
        const getCountries = async (experimentId) => {
            try {
                const resp = await request(`experiment/${ experimentId }/country`).get();
                
                const countries = resp.map(c => {
                    if (c.country === '') {
                        c.country = 'Not defined';
                    }
                    
                    return {
                        value: c.country,
                        text: c.country,
                        subText: c.count
                    }
                }).concat(defaultCountries);
                
                setItems(countries);
            } catch (e) {
                console.log(e);
            }
        }
        getCountries(experimentId);
    }, [experimentId]);

    const handleChangeCountrySelect = e => {
        setValue(e);
    };

    useEffect(() => {
        if (onSelect) {
            onSelect(value);
        }
    }, [value, onSelect]);

    return (
        <FormControl className={ classes.formControl }>
            <Select label={ 'Country' }
                    multiSelect
                    onChange={ handleChangeCountrySelect }
                    items={ items }
                    defaultSelect={ value }/>
        </FormControl>
    )
}
