import React from "react";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        border: theme.palette.borderStyle,
        padding: 24,
        height: 'auto',
        minHeight: 'calc(100vh - 145px)'
    }
}))


interface Props {
    className?: string
}

const PaperContainer: React.FC<Props> = (props) => {
    const classes = useStyles();

    return (
        <Paper elevation={ 0 } className={ clsx(classes.root, props.className) }>
            { props.children }
        </Paper>
    )
}

export default PaperContainer;

