export const SET_EXPERIMENT_ID = 'SET_EXPERIMENT_ID';
export const SET_EXPERIMENT_BASE_INFO = 'SET_EXPERIMENT_BASE_INFO';

export const SET_EXPERIMENT_REVENUES = 'SET_EXPERIMENT_REVENUES';
export const SET_EXPERIMENT_IMPRESSIONS = 'SET_EXPERIMENT_IMPRESSIONS';
export const SET_EXPERIMENT_FUNNEL = 'SET_EXPERIMENT_FUNNEL';
export const SET_EXPERIMENT_METRICS = 'SET_EXPERIMENT_METRICS';

export function setExperimentId(id) {
    return {
        type: SET_EXPERIMENT_ID,
        data: id
    }
}

export function setExperimentRevenues(data) {
    return {
        type: SET_EXPERIMENT_REVENUES,
        data: data
    }
}

export function setExperimentBase(data) {
    return {
        type: SET_EXPERIMENT_BASE_INFO,
        data: data
    }
}

export function setExperimentImpressions(data) {
    return {
        type: SET_EXPERIMENT_IMPRESSIONS,
        data: data
    }
}

export function setExperimentFunnel(data) {
    return {
        type: SET_EXPERIMENT_FUNNEL,
        data: data
    }
}

export function setExperimentMetrics(data) {
    return {
        type: SET_EXPERIMENT_METRICS,
        data: data
    }
}


