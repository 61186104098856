import React, {useEffect} from "react";
import {animated, useSpring} from "react-spring";
import {Snackbar} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import MuiAlert from "@material-ui/lab/Alert";
import {useDispatch, useSelector} from "react-redux";
import {closeAlert} from "../../redux/actions/alert";



const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        left: '45%',
        height: 50,
        width: 100,
        zIndex: 99999,
        top: '11vh'
    }
}))

const snackStyleHidden = {
    transform: `translate(0, -100px)`,
    opacity: 0
}

const snackStyleShow = {
    opacity: 1,
    transform: 'translate(0, 0)'
}

export default function Alert() {
    const classes = useStyles();
    const [snakStyle, setSnackStyle] = useSpring(() => snackStyleHidden);


    // @ts-ignore
    const {open, message} = useSelector(store => store.alert);
    const dispatch = useDispatch();

    useEffect(() => {
        setSnackStyle(open ? snackStyleShow : snackStyleHidden);
    }, [open, setSnackStyle]);

    const handleCloseSnackBar = () => {
        dispatch(
            closeAlert()
        )
    }

    return (
        <animated.div style={snakStyle} className={classes.root}>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                style={{
                    width: 200
                }}
                open={open}
                /*direction="down"*/
                onClose={handleCloseSnackBar}
                autoHideDuration={1000}
            >
                <MuiAlert  elevation={3}>
                    {message}
                </MuiAlert>
            </Snackbar>
        </animated.div>
    )
}
