import { Box } from "@material-ui/core";
import React from "react";


interface Props{
    value: number,
    index: number,
    className?: string
}

const TabPanel: React.FC<Props> = (props) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default TabPanel
