import React, { ChangeEvent, useState } from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 20,
        alignItems: 'center',
        display: 'flex',
        flexBasis: 320,
        height: 32,
        paddingLeft: theme.spacing(1),
        //@ts-ignore
        background: theme.palette.white,
        transition: '0.3s'
    },
    icon: {
        color: theme.palette.icon,
    },
    input: {
        flexGrow: 1,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '-0.05px'
    },
    focus: {
        //flexBasis: 420,
    }
}));

interface Props{
    className: string
}

const SearchInput = (props: Props) => {
    const classes = useStyles();
    const [value, setValue] = useState<string>('');

    const handleClear = () => {
        setValue('');
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value);


    return (
        <Paper
            className={clsx(classes.root, props.className)}
            elevation={0}
        >

            <IconButton>
                <SearchIcon className={classes.icon}/>
            </IconButton>

            <Input className={classes.input}
                   disableUnderline
                   onChange={handleChange}
                   value={value}
            />

            <Fade in={value !== ''}>
                <IconButton onClick={handleClear} size={'small'}>
                    <CancelIcon/>
                </IconButton>
            </Fade>

        </Paper>
    );
}

export default SearchInput;
