import {SET_PROFILE, RESET_PROFILE} from "../actions/profile";

const initialState = {
    id: null,
    name: '',
    isAdmin: false
};

export default function snackbar(state = initialState, action) {
    switch (action.type) {
        case SET_PROFILE:
            return {
                id: action.data.id,
                name: action.data.name,
                isAdmin: action.data.is_admin === 1
            }
        case RESET_PROFILE: {
            return initialState
        }
        default:
            return state;
    }
}
