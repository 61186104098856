import React, { useEffect, useRef, useState } from "react";
import {
    FormControl,
    InputLabel,
    MenuList,
    MenuItem,
    IconButton,
    Popover,
    Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { BootstrapInput } from '../BootstrapInput/BootstrapInput';
import Checkbox from "../Checkbox";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    select: {
        width: 200
    },
    icon: {
        width: 24,
        transition: '0.3s'
    },
    iconOpen: {
        transform: 'rotate(180deg)'
    },
    menuList: {
        //marginTop: 4,
        borderRadius: 4,
        maxHeight: 256,
        overflowY: 'auto',
    },
    multiSelectMenuItem: {
        display: "flex",
        alignItems: 'center'
    },
    menuItem: {
        color: theme.palette.black,
        display: 'flex',
        //justifyContent: 'space-between'
    },
    menuItemSelected: {
        background: theme.palette.selectionLight
    },
    label: {
        color: theme.palette.black,
        fontSize: 18,
        fontWeight: 500,
        fontLineHeight: 24
    },
    input: {
        height: 40
    },
    popover: {
        '&.MuiPopover-paper': {
            border: theme.palette.borderStyle,
            marginTop: 4
        }
    },
    subText: {
        fontStyle: 'italic',
        flex: 1,
        textAlign: 'end'
    },
}));

interface ISelectItem {
    text: string,
    value: any,
    subText?: string
}

interface Props {
    fullWidth?: boolean,
    items: ISelectItem[],
    placeholder?: string,
    multiSelect?: boolean,
    enableSearch?: boolean,
    label?: string,
    defaultSelect: string,
    defaultSelected?: string[],
    onChange: (v: any) => void;
    disabled?: boolean
}


const Select = (props: Props) => {
    const classes = useStyles();
    const [selected, setSelected] = useState<string>(props.defaultSelect);
    const inputRef = useRef<HTMLInputElement>();
    const rootRef = useRef();
    const [inputValue, setInputValue] = useState("");
    const [values, setValues] = useState<ISelectItem[]>([]);
    const [anchorEl, setAnchorEl] = useState<any>(null);


    const  {onChange, defaultSelect, items} = props;

    useEffect(() => {
        setValues(items);
        setInputValue("");
    }, [items])


    useEffect(() => {
        const regex = new RegExp(`${inputValue}`, 'gi');
        setValues(items.filter((item) => {
            return item.text.match(regex);
        }));
    }, [inputValue, items])


    useEffect(() => {
        onChange(selected);
    }, [selected]);


    useEffect(() => {
        setSelected(defaultSelect);
    }, [items, defaultSelect]);


    const handleOpenBtn = () => setAnchorEl(inputRef.current || null);

    const handleClickMenuItem = (value: string) => {
        setSelected(value);
        handleClose()
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleClickMultiSelect = (v: { value: string, checked: boolean }) => {
        const {checked, value} = v;
        if (checked) {
            setSelected(
                selected.split(',').filter(v => v).concat(value).join(',')
            )
        } else {
            setSelected(
                selected.split(',').filter(v => v !== value).join(',')
            )
        }
    }

    const display = selected.split(',').map(value => {
        const find = props.items.find(i => i.value === value);
        return find ? find.text : ''
    }).join();

    return (
        <FormControl fullWidth={props.fullWidth} ref={rootRef.current}>
            {
                props.label && <InputLabel className={classes.label} shrink={true}>{props.label}</InputLabel>
            }

            <BootstrapInput
                ref={inputRef}
                placeholder={props.placeholder}
                disabled={props.disabled}
                value={display}
                onPointerDown={props.disabled ? undefined : handleOpenBtn}
                className={clsx('disableCaret', classes.input)}
                endAdornment={
                    <IconButton onClick={props.disabled ? undefined : handleOpenBtn}
                                size={'small'}
                                disabled={props.disabled}>
                        <i className={clsx("fal fa-angle-down", classes.icon, anchorEl ? classes.iconOpen : '')}/>
                    </IconButton>
                }/>

            <Popover
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                elevation={0}
                PaperProps={{
                    className: classes.popover,
                    style: {
                        width: inputRef.current?.offsetWidth || 'auto'
                    }
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuList className={classes.menuList}>
                    {props.enableSearch && <MenuItem className={clsx(classes.multiSelectMenuItem, classes.menuItem)}>
                        <BootstrapInput
                            onKeyDown={e => e.stopPropagation()}
                            onChange={(e) => {
                                setInputValue(e.target.value)
                            }}
                            placeholder={"Start typing "}
                            value={inputValue}
                        />
                    </MenuItem>
                    }
                    {
                        props.multiSelect
                            ? (
                                values.filter(({ value }) => value).map(({ value, text, subText }) => {
                                        const checked = selected.split(',').includes(value);

                                        return (
                                            <MenuItem className={clsx(classes.multiSelectMenuItem, classes.menuItem)}
                                                      onClick={e => {
                                                          e.preventDefault();
                                                          handleClickMultiSelect({ value, checked: !checked })
                                                      }}
                                                      key={`${value}`}>

                                                <Checkbox checked={checked}
                                                          onClick={e => {
                                                              e.preventDefault();
                                                              handleClickMultiSelect({
                                                                  value,
                                                                  checked: !checked
                                                              })
                                                          }}/>

                                                {text || value}

                                                <Typography variant={'body2'} className={classes.subText}>
                                                    {subText}
                                                </Typography>
                                            </MenuItem>
                                        )
                                    }
                                )

                            )
                            : (
                                values.map(({ value, text, subText }) =>
                                    <MenuItem onClick={() => handleClickMenuItem(value)}
                                              className={clsx(classes.menuItem, value === selected ? classes.menuItemSelected : '')}
                                              key={`${value}`}>
                                        {text || value}

                                        {
                                            subText && (
                                                <Typography variant={'body2'} className={classes.subText}>
                                                    {subText}
                                                </Typography>
                                            )
                                        }
                                    </MenuItem>)

                            )
                    }
                </MenuList>
            </Popover>
        </FormControl>
    )
}


export default Select;
