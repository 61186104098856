import React from "react";
import {useDropzone} from "react-dropzone";
import {Button} from "components/index";
import IconButton from "@material-ui/core/IconButton";
import {withStyles, makeStyles} from '@material-ui/core/styles';
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    icon: {
        color: theme.palette.primary.main
    }
}))


const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        height: '2.5em',
        width: '2.5em',
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}))(IconButton);

interface UploadBtnProps{
    className?: string,
    accept?: string | string[],
    onUpdate: (files: any[]) => void;
    circle?: boolean,
    iconButton?: React.ReactElement,
    color?: "primary" | "secondary",
    loading: boolean
}


const UploadBtn: React.FC<UploadBtnProps> = (props) => {

    const mProps = {
        color: "primary" as "primary",
        circle: false,
        ...props
    }
    const {children, accept, circle, onUpdate, className, iconButton, color, loading} = mProps;
    const classes = useStyles();

    const {getRootProps, getInputProps} = useDropzone({
        onDrop: (files) => {
            onUpdate(files)
        },
        accept: accept
    });


    return (
        <div className={className}>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    circle
                        ? (
                            <ColorButton color={color}  size={'medium'}>
                                {
                                    iconButton ? iconButton : <i className="fal fa-cloud-download"/>
                                }
                            </ColorButton>
                        )
                        : (
                            <Button color={color}
                                    loading={loading}
                                    variant={'contained'}
                                    removeStopPropagation={true}
                                    startIcon={(!loading && iconButton) ? iconButton : <i className={clsx("fal fa-cloud-download", classes.icon)}/>}>
                                {children}
                            </Button>
                        )
                }
            </div>
        </div>
    )
}

export default UploadBtn;
