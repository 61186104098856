import React from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '2px 8px',
        borderRadius: 16,
        display: 'inline-flex',
        alignItems: 'center'
    },
    success: {
        //@ts-ignore
        background: theme.palette.mint,
        color: theme.palette.green,
    },
    fail: {
        background: theme.palette.pink,
        color: theme.palette.red
    },
    improvement: {
        background: theme.palette.selectionLight,
        color: theme.palette.primary.main
    },
    insufficient: {
        //@ts-ignore
        background: theme.palette.disabled,
        color: theme.palette.dimGrey
    },
    icon: {
        fontSize: 11
    },
    pointer: {
        cursor: 'pointer'
    },
    content: {},
    text: {
        textAlign: 'center',
        fontSize: 11,
        marginLeft: 4,
        fontWeight: 500,
        textTransform: 'uppercase',
        fontFamily: [
            'Inter',
            'Ubuntu',
            'Roboto',
            'sans-serif',
        ].join(',')
    }
}))

interface Props{
    type: 'success' | 'fail' | 'insufficient' | 'possible' | 'improvement',
    onClick?: () => void
}

const Tag: React.FC<Props> = ({ type, onClick }) => {
    const classes = useStyles();

    const defaultClass = onClick ? classes.pointer : '';

    if (type === 'success') {
        return (
            <span className={ clsx(defaultClass, classes.root, classes.success) } onClick={onClick}>
                 <i className={ clsx("far fa-check", classes.icon) }/>
                 <span className={ clsx(classes.text) }>success</span>
            </span>
        )
    }

    if (type === 'fail') {
        return (
            <span className={ clsx(defaultClass, classes.root, classes.fail) } onClick={onClick}>
                <i className={ clsx("far fa-times", classes.icon) }/>
                <span className={ clsx(classes.text) }>fail</span>
            </span>
        )
    }

    if (type === 'improvement') {
        return (
            <span className={ clsx(defaultClass, classes.root, classes.improvement) } onClick={onClick}>
                <i className={ clsx("far fa-arrow-alt-circle-up", classes.icon) }/>
                <span className={ clsx(classes.text) }>possible improvement</span>
            </span>
        )
    }

    if (type === 'insufficient') {
        return (
            <span className={ clsx(defaultClass, classes.root, classes.insufficient) } onClick={onClick}>
                <i className={ clsx("far fa-question-circle", classes.icon) }/>
                <span className={ clsx(classes.text) }>insufficient data</span>
            </span>
        )
    }

    return (
        <div className={ classes.root }>
            invalid type tag
        </div>
    )
}


export default Tag;
