import {SET_SNACKBAR, HIDE_SNACKBAR} from "../actions/snackbar";

const initialState = {
    open: false,
    text: ''
};

export default function snackbar(state = initialState, action) {
    switch (action.type) {
        case SET_SNACKBAR:
            return {
                open: true,
                text: action.data,
                severity: action.severity
            }
        case HIDE_SNACKBAR: {
            return {
                open: false,
                text: ''
            }
        }
        default:
            return state;
    }
}
