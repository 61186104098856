import {combineReducers} from 'redux';

import snackbar from "./snackbar";
import profile from "./profile";
import projects from "./projects";
import test from "./test";
import tests from "./tests";
import alert from "./alert";
import projectTypes from "./projectTypes";
import experiment from './experiment';

export default combineReducers({
    snackbar,
    profile,
    projects,
    test,
    tests,
    alert,
    projectTypes,
    experiment
});
