import palette from '../palette';


export default {
  gutterBottom: {
    marginBottom: 8
  },
  body1: {
    color: palette.primary.black
  },
  h2: {
    color: palette.primary.black
  },
  h3: {
    color: palette.primary.black
  },
  h4: {
    color: palette.primary.black
  },
  h5: {
    color: palette.primary.black
  }
};
