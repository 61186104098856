import React from "react";
import { Dialog as DialogMui, DialogProps, DialogTitle, IconButton, Slide } from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/styles";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";


const Transition = React.forwardRef((props, ref) => {
    //@ts-ignore
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '16px 16px 0 0',
        border: "none",
        height: 24
    },
    closeIcon: {
        color: theme.palette.primary.main,
        width: 24,
        height: 24,
        padding: 0
    },
}))


interface Props extends DialogProps {
    onClose: () => void;
    open: boolean,
    className?: string,
}

export const Dialog: React.FC<Props> = (props) => {
    const { onClose, open, children, className, ...others } = props;
    const classes = useStyles();
    const theme = useTheme();

    const handleCloseBtn = () => {
        props.onClose()
    }

    const isMobile = useMediaQuery(theme.breakpoints.down('md'), {
        defaultMatches: true
    });


    return (
        <DialogMui onClose={handleCloseBtn}
                   fullScreen={isMobile}
                   open={open}
                   //@ts-ignore
                   TransitionComponent={Transition}
                   keepMounted
                   scroll={'body'}
                   className={props.className}
                   {...others} >
            <div>
                <DialogTitle disableTypography className={classes.dialogTitle}>
                    <IconButton onClick={handleCloseBtn}>
                        <i className={clsx("fal fa-times", classes.closeIcon)}/>
                    </IconButton>

                </DialogTitle>

                {props.children}
            </div>

        </DialogMui>
    )
}

export default Dialog;
