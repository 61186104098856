import React from "react";
import Typography from "@material-ui/core/Typography";
import {AppBar, Toolbar} from '@material-ui/core';
import {makeStyles} from "@material-ui/styles";

import PropTypes from 'prop-types';

import ABSVG from '../../images/AB.svg';

const useStyle = makeStyles(theme => ({
    root: {
        display: "flex",
        background: theme.palette.black,
    },
    logo: {
        fontWeight: 'bold',
        marginLeft: 8,
        color: '#F9F9F9'
    },
    search: {
        marginLeft: 50
    },
    flexGrow: {
        flexGrow: 1
    },
    toolbar: {
        height: 56
    }
}));

Navbar.propTypes = {
    children: PropTypes.node
}

export default function Navbar({children}) {
    const classes = useStyle();

    return (
        <AppBar className={classes.root} elevation={0}>
            <Toolbar className={classes.toolbar}>
                <img src={ABSVG} width={48} height={24}  alt=''/>

                <Typography className={classes.logo}
                            color={'primary'}
                            variant={'h3'}>
                    TEST REAL
                </Typography>

                <div className={classes.flexGrow} />

                {/*<Languages />*/}

                <div>
                    {children}
                </div>
            </Toolbar>
        </AppBar>
    )
}
