import React, {useState} from "react";
import Collapse from "@material-ui/core/Collapse";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {makeStyles} from "@material-ui/styles";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CheckIcon from '@material-ui/icons/Check';
import clsx from "clsx";
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    item: {
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.background.default
        }
    },
    text: {
        '& .MuiTypography-body1': {
            color: theme.palette.primary.main
        }
    },
    icon: {
        display: 'flex',
        justifyContent: 'flex-end',
        color: theme.palette.primary.main,
        '& .MuiSvgIcon-root': {
            transition: '0.3s'
        }
    },
    openIcon: {
        '& .MuiSvgIcon-root': {
            transform: 'rotate(180deg)'
        }
    }
}));

CollapseSelect.propTypes = {
    label: PropTypes.string,
    items: PropTypes.array,
    onSelect: PropTypes.func,
    children: PropTypes.node,
    expand: PropTypes.bool
}

CollapseSelect.defaultProps = {
    expand: false
}

export default function CollapseSelect(
    {
        label,
        selectId,
        items,
        onSelect,
        children,
        expand
    }
) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(!open);
    }

    const handleSelectItem = item => {
        if (onSelect) {
            onSelect(item.id, item);
        }
        handleClickOpen();
    }

    const find = items.find(i => i.id === selectId);

    const selectionText = find ? find.text : label;

    return (
        <>
            <ListItem onClick={handleClickOpen} className={classes.item}>
                {
                    !expand && (
                        <>
                            <ListItemText className={classes.text}>{selectionText}</ListItemText>
                            <ListItemIcon className={clsx(classes.icon, open ? classes.openIcon : '')}>
                                <ExpandLessIcon/>
                            </ListItemIcon>
                        </>
                    )
                }


            </ListItem>
            <Collapse in={Boolean(open || expand)}>
                {
                    items.map(item => (
                        <ListItem key={item.id} className={classes.item} onClick={() => handleSelectItem(item)}>
                            <ListItemText className={classes.text}>{item.text}</ListItemText>
                            {
                                item.id === selectId ? (
                                    <ListItemIcon className={classes.icon}>
                                        <CheckIcon/>
                                    </ListItemIcon>
                                ) : ''
                            }
                        </ListItem>
                    ))
                }

                {children}
            </Collapse>
        </>
    )
}