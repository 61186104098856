import {indigo, blue, blueGrey} from '@material-ui/core/colors';

const white = '#FFFFFF';
const materialShadow = '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)';

const primaryColor =  '#6B7FE3';
const selection = '#E5E7F6';
const selectionLight = '#F5F7FF';

const black = '#161F29';
const darkGrey = '#46474F';
const dimGrey = '#7C7E8B';
const grey = '#DADCDF';
const border = '#EDEEF3';
const disabled = '#F6F6F6';
const bg = '#F9F9F9';
const green = '#64B26C';
const mint = '#F2F6F3';
const red = '#D35D47';
const pink = '#FEEEEE';
const orange = '#EDAC2E';
const yellow = '#FFF9E5';
const brown = '#928B74';

export default {
  black,
  white,
  materialShadow,
  primaryColor,
  selection,
  selectionLight,
  darkGrey,
  dimGrey,
  border,
  bg,
  green,
  mint,
  red,
  pink,
  orange,
  yellow,
  brown,
  primary: {
    contrastText: white,
    dark: '#5462bb',
    main: '#6B7FE3',
    light: '#6B7FE3',
    menu: indigo[500]
  },
  secondary: {
    contrastText: white,
    dark: '#F5F7FF',
    main: '#F5F7FF',
    light: '#F5F7FF'
  },
  success: {
    contrastText: white,
    dark: green,
    main: green,
    light: green
  },
  info: {
    contrastText: white,
    dark: blue[900],
    main: blue[600],
    light: blue[400]
  },
  warning: {
    contrastText: white,
    dark: orange,
    main: orange,
    light: orange
  },
  error: {
    contrastText: white,
    dark: red,
    main: red,
    light: red
  },
  delete: {
    contrastText: white,
    dark: red,
    main: red,
    light: red
  },
  text: {
    primary: blueGrey[900],
    secondary: blueGrey[600],
    link: blue[600],
    disable: grey,
    black: black
  },
  background: {
    default: bg,
    paper: white
  },
  icon: blueGrey[600],
  divider: disabled,
  disabled: disabled,
  borderStyle: 'solid 1px #EDEEF3'
};
