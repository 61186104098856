import palette from '../palette';

export default {
    popper: {
        zIndex: 9999,
        //background: palette.black
    },
    tooltip: {
        backgroundColor: palette.black
    },
    arrow: {
        color: palette.black
    }
};
