import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';
import MuiDialogTitle from "./MuiDialogTitle";
import MuiStepIcon from "./MuiStepIcon";
import MuiTooltip from "./MuiTooltip";
import MuiCard from "./MuiCard";
import MuiTable from './MuiTable';

export default {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,
  MuiDialogTitle,
  MuiStepIcon,
  MuiTooltip,
  MuiCard,
  MuiTable
}
