import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import {
    FormControl,
    InputLabel,
    FormHelperText,
    Collapse,
    IconButton,
    InputAdornment,
    Typography, MenuList, MenuItem, Paper, InputBaseProps, InputBaseComponentProps
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { BootstrapInput } from '../BootstrapInput/BootstrapInput';
import { changeEventHandlerDecorator } from "../../utils/forms";

const useStyles = makeStyles(theme => ({
    root: {
        '&.MuiInputBase-multiline': {
            padding: 0
        }
    },
    formHelperText: {
        backgroundColor: theme.palette.pink,
        margin: 0,
        marginTop: -3,
        padding: '8px 6px',
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4
    },
    formHelperTextTypography: {
        fontSize: 14,
        color: theme.palette.red
    },
    successInput: {
        border: `1px solid ${ theme.palette.green } !important`,
    },
    checkIcon: {
        color: theme.palette.green
    },
    warningInput: {
        border: `1px solid ${ theme.palette.orange } !important`,
    },
    warningIcon: {
        color: theme.palette.orange
    },
    label: {
        color: theme.palette.black,
        fontSize: 18,
        fontWeight: 500,
        fontLineHeight: 24
    },
    visibility: {
        color: theme.palette.primaryColor
    },
    menuList: {
        //marginTop: 4,
        borderRadius: 4,
        maxHeight: 256,
        overflowY: 'auto',
    },
    multiSelectMenuItem: {
        display: "flex",
        alignItems: 'center'
    },
    menuItem: {
        color: theme.palette.black,
        display: 'flex',
        //justifyContent: 'space-between'
    },
}));
/*

TextField.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    onClear: PropTypes.func,
    success: PropTypes.bool,
    className: PropTypes.string,
    multiline: PropTypes.bool,
    type: PropTypes.oneOf(['text', 'password']),
    fullWidth: PropTypes.bool,
    chooseItems: PropTypes.array
}

TextField.defaultProps = {
    error: false,
    success: false,
    warning: false,
    type: 'search',
    fullWidth: false,
    chooseItems: []
}
*/


interface Props {
    label?: string,
    placeholder?: string,
    disabled?: boolean,
    value?: string,
    name: string,
    helperText?: string,
    error?: boolean,
    success?: boolean,
    warning?: boolean,
    multiline?: boolean,
    type: 'search' | 'password' | 'number',
    className?: string,
   /* ref?: MutableRefObject<any>,*/
    inputRef?: React.Ref<any>,
    onClear?: () => void;
    onFocus?: () => void;
    onChange?: (value: string) => void;
    onBlur?: () => void;
    chooseItems?: any[],
    endAdornment?: JSX.Element,
    fullWidth?: boolean,
    rows?: number,
    inputProps?: InputBaseComponentProps,
    autoComplete?: string,
    helperTextProps?: any,
    variant?: "outlined" | "contained",
    color?: 'primary' | 'secondary'
}

/*export default function TextField(
    {
        label,
        placeholder,
        disabled,
        error,
        helperText,
        onClear,
        success,
        className,
        warning,
        multiline,
        type,
        value,
        inputRef,
        fullWidth,
        name,
        rows,
        endAdornment,
        onChange,
        HelperTextProps,
        onFocus,
        onBlur,
        maxLength,
        autoComplete,
        ref,
        chooseItems,
        inputProps
    }
) {*/
const TextField = (props: Props) => {
    const classes = useStyles();
    let endAdornment = null;
    let inputClassName = clsx(props.className, classes.root);
    let ref = useRef()
   /* if (!ref) {
        ref = useRef();
    }*/

    if (props.error) {
        endAdornment = (
            <InputAdornment position="end">
                <CloseIcon fontSize={ 'small' }/>
            </InputAdornment>
        )
    }

    if (props.success) {
        inputClassName = clsx(props.className, classes.successInput)
        endAdornment = (
            <InputAdornment position="end">
                <CheckIcon fontSize={ 'small' } className={ classes.checkIcon }/>
            </InputAdornment>
        )
    }

    if (props.warning) {
        inputClassName = clsx(props.className, classes.warningInput)
        endAdornment = (
            <InputAdornment position="end">
                <WarningIcon fontSize={ 'small' } className={ classes.warningIcon }/>
            </InputAdornment>
        )
    }

    const [inputType, setInputType] = useState(props.type);

    if (props.type === 'password') {
        const handleClickShowPassword = () => {
            setInputType(
                inputType === 'password' ? 'search' : 'password'
            )
        }

        endAdornment = (
            <InputAdornment position="end">
                <IconButton onClick={ handleClickShowPassword } edge="end">
                    {
                        inputType === 'password'
                            ? <i className={ clsx("far fa-eye-slash", classes.visibility) }/>
                            : <i className={ clsx('far fa-eye', classes.visibility) }/>
                    }
                </IconButton>
            </InputAdornment>
        )
    }

    const [_value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickMenuItem = (value: string) => {
        setValue(value);
        if (props.onChange) {
            props.onChange(value)
        }
    }


    const handleFocus = () => {
        if (props.chooseItems) {
            //@ts-ignore
            setAnchorEl(ref ? ref : null)
        }
        props.onFocus && props.onFocus();
    }

    const handleBlur = () => {
        setTimeout(() => {
            setAnchorEl(null)
        }, 100);
    }


    return (
        <FormControl fullWidth={ props.fullWidth } onBlur={handleBlur}>

            {
                props.label && (
                    <InputLabel shrink className={ classes.label }>
                        { props.label }
                    </InputLabel>
                )
            }

            <BootstrapInput placeholder={ props.placeholder }
                            disabled={ props.disabled }
                            multiline={ props.multiline }
                            className={ inputClassName }
                            endAdornment={ props.endAdornment || endAdornment }
                            value={ props.chooseItems && props.chooseItems.length > 0 ? _value : props.value }
                            type={ inputType }
                            name={ props.name }
                            ref={ ref }
                            onChange={ props.onChange && changeEventHandlerDecorator(props.onChange) }
                            inputRef={ props.inputRef }
                            fullWidth={ props.fullWidth }
                            onFocus={ handleFocus }
                            onBlur={ props.onBlur }
                            rows={ props.rows }
                            autoComplete={ props.autoComplete }
                            inputProps={ props.inputProps}
                            error={ props.error }
                            key={props.name}
                            id={props.name}
                            color={props.color}
            />

            <Collapse in={ props.error && !!props.helperText }>
                <FormHelperText
                    className={ clsx(classes.formHelperText, props.helperTextProps ? props.helperTextProps.className : '') }
                    component={ 'div' }>
                    <Typography className={ classes.formHelperTextTypography } component={ 'nav' }>
                        { props.helperText }
                    </Typography>
                </FormHelperText>
            </Collapse>


            {
                props.chooseItems && props.chooseItems.length > 0 && (
                    <Collapse in={ Boolean(anchorEl) }>
                        <Paper elevation={ 0 }
                               style={ {
                                   width:  'auto'
                               } }

                               //@ts-ignore
                               anchorOrigin={ {
                                   vertical: 'bottom',
                                   horizontal: 'left',
                               } }
                                //@ts-ignore
                               transformOrigin={ {
                                   vertical: 'top',
                                   horizontal: 'left',
                               } }
                        >
                            <MenuList className={ classes.menuList }>
                                {
                                    props.chooseItems && props.chooseItems.map(c => <MenuItem key={ c }
                                                                   onClick={ () => handleClickMenuItem(c) }>{ c }</MenuItem>)
                                }
                            </MenuList>

                        </Paper>
                    </Collapse>
                )
            }


        </FormControl>
    )
}


export default TextField;
