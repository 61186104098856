import React from "react";
import {makeStyles} from "@material-ui/styles";
import {CircularProgress} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.background.default,
        width: '100%',
        flexDirection: 'column',
        height: '100%'
    }
}));


interface Props{
    className?: string
}
const LoadingScreen = (props: Props)  => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, props.className)}>
            <CircularProgress />
        </div>
    )
}

export default LoadingScreen;
