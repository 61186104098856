export const SET_PROFILE = 'SET_PROFILE';
export const RESET_PROFILE = 'RESET_PROFILE';

export function setProfile(data) {
    return {
        type: SET_PROFILE,
        data: data
    }
}

export function resetProfile() {
    return {
        type: RESET_PROFILE
    }
}
