import React from "react";
import {TableCell, TableHead, TableRow, TableSortLabel} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

HeadCells.propTypes = {
    items: PropTypes.array
}

HeadCells.defaultProps = {
    items: []
}

export default function HeadCells({items, order, orderBy, onRequestSort}) {
    const classes = useStyles();

    const createSortHandler = (property) => (event) => {
        if (onRequestSort) {
            onRequestSort(event, property);
        }
    };

    return (
        <TableHead>
            <TableRow>
                {items.map((headCell) => {
                    if (headCell.sort) {
                        return (
                            <TableCell
                                key={headCell.id}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {
                                        orderBy === headCell.id ? (
                                            <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                        ) : null
                                    }
                                </TableSortLabel>
                            </TableCell>
                        )
                    }

                    return (
                        <TableCell key={headCell.label}>
                            {
                                headCell.label
                            }
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}
