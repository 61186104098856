import { GeneralResponse, request } from "../../api";
import {
    ICreateProjectRequest,
    IGetAudienceParamsRequest,
    IGetAudienceParamsResponse,
    IGetInappsFilteredRequest,
    IGetInappsFilteredResponse,
    IGetProjectLogsRequest,
    IGetProjectLogsResponse,
    IGetProjectsRequest,
    IGetProjectsResponse,
    IGetServiceAccountsResponse,
    IRemoveProjectRequest,
    IRemoveProjectResponse,
    IRemoveUserFromProjectRequest,
    IRemoveUserFromProjectResponse
} from "./requestResponses";
import { IGetInappsByRangeRequest, IGetInappsByRangeResponse } from "../experiments/requestResponses";


export const getProjects = async (params: IGetProjectsRequest): Promise<GeneralResponse<IGetProjectsResponse>> => {
    const res =  await request<IGetProjectsRequest, IGetProjectsResponse>('projects', params, {}).get();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}

export const getServiceAccounts = async (params: {
    projectId: number
}): Promise<GeneralResponse<IGetServiceAccountsResponse>> => {

    const res =  await request<{  }, IGetServiceAccountsResponse>(
        `projects/${params.projectId}/serviceAccounts`,
        params, {}
    ).get();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}


export const createProject = async (params: ICreateProjectRequest): Promise<GeneralResponse<{  }>> => {
    const res =  await request<ICreateProjectRequest, { }>('projects', params, {}).post();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}

export const removeProject = async (params: {
    projectId: number
}): Promise<GeneralResponse<IRemoveProjectResponse>> => {
    const res =  await request<IRemoveProjectRequest, {}>(`projects/${params.projectId}`, {  }, {}).delete();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}

export const removeUserFromProject = async (params: {
    projectId: number,
    uid: string
}):
    Promise<GeneralResponse<IRemoveUserFromProjectResponse>> => {
    const res =  await request<IRemoveUserFromProjectRequest, IRemoveUserFromProjectResponse>(
        `projects/${ params.projectId }/users/${ params.uid }`,
        {}, {}
    ).delete();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}


export const getProjectLogs = async (projectId: string): Promise<GeneralResponse<IGetProjectLogsResponse>> => {
    const res =  await request<IGetProjectLogsRequest, IGetProjectLogsResponse>(
        `projects/${ projectId}/logs`,
        {  }, {}
    ).get();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}


export const getAudienceParams = async (projectId: number): Promise<GeneralResponse<IGetAudienceParamsResponse>> => {
    const res =  await request<IGetAudienceParamsRequest, IGetAudienceParamsResponse>(
        `projects/${ projectId}/getAllAudienceParametersName`,
        {  }, {}
    ).get();

    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}


export const getInappsFiltered = async (projectGuid: string, params: IGetInappsFilteredRequest): Promise<GeneralResponse<IGetInappsFilteredResponse>> => {
    const res =  await request<IGetInappsFilteredRequest, IGetInappsFilteredResponse>(
        `projects/${projectGuid}/getInAppsFiltered`, params, {}).get();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}
