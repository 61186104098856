export const SET_SNACKBAR = 'SET_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';

export function setSnackbar(data, severity = 'info') {
    return {
        type: SET_SNACKBAR,
        data: data,
        severity: severity
    }
}

export function hideSnackbar() {
    return {
        type: HIDE_SNACKBAR
    }
}
