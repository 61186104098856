import React from "react";
import { LoadingScreen, SpringOpacity } from "components";



interface Props{
    loading: boolean,
    className?: string
}


const LoadingContainer: React.FC<Props> = (props) => {
    return props.loading ? <LoadingScreen className={props.className} /> : (
        <SpringOpacity show={true} spring={200}>
            {props.children}
        </SpringOpacity>
    )
}

export default LoadingContainer
