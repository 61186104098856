class RequestsController {
    constructor() {
        this.requests = [];
    }

    cancelRequest(url) {
        this.requests = this.requests.filter(r => {
            if (r.url === url) {
                r.cancelToken.cancel();

                return false;
            }

            return true;
        })
    }

    addRequest(url, cancelToken) {
        this.requests.push({
            url,
            cancelToken
        })
    }

    removeRequest(url) {
        this.requests = this.requests.filter(r => r.url !== url)
    }
}

export default RequestsController;