import React from "react";

import { withStyles } from '@material-ui/core/styles';
import { Tabs as _Tabs, TabsProps } from '@material-ui/core';

//TODO:
//@ts-ignore
const StyledTabs = withStyles(theme => ({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.grey,
        '& > span': {
            width: '100%',
            backgroundColor: theme.palette.primary.main,
        },
    },
}))((props) =>
    <_Tabs { ...props } TabIndicatorProps={ { children: <span/> } }/>);


interface Props extends TabsProps{}

const Tabs: React.FC<Props> = (props) => {
    const {children, ...others} = props;
    return (
        <StyledTabs {...others}
                    aria-label="Tabs">
            { props.children }
        </StyledTabs>
    );
}


export default Tabs;
