import React from 'react';
import {Button} from 'components/index';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme, makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    header: {
        fontWeight: 'bold'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

SimpleDialog.propTypes = {
    children: PropTypes.node,
    header: PropTypes.string,
    text: PropTypes.string,
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    cancelStr: PropTypes.string,
    confirmStr: PropTypes.string,
    contentContainerProps: PropTypes.object,
    fullscreen: PropTypes.bool
}

SimpleDialog.defaultProps = {
    open: false,
    isDisableConfirm: false,
    loading: false
}


export default function SimpleDialog(
    {
        children,
        header,
        text,
        open,
        onCancel,
        onConfirm,
        cancelStr,
        confirmStr,
        contentContainerProps,
        fullscreen,
        isDisableConfirm,
        loading
    }
) {
    const classes = useStyles();

    const handlerConfirm = () => {
        if (onConfirm) {
            onConfirm()
        }
    }

    const handlerCancel = () => {
        if (onCancel) {
            onCancel();
        }
    }

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'), {
        defaultMatches: true
    });


    return (
        <Dialog
            open={open}
            fullScreen={fullscreen || isMobile}
            TransitionComponent={Transition}
            keepMounted
            onClose={handlerCancel}
        >
            <DialogTitle disableTypography>
                <Typography variant={'h4'} className={classes.header}>
                    {header}
                </Typography>

            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {text}
                </DialogContentText>
                <div {...contentContainerProps}>
                    {children}
                </div>
            </DialogContent>

            {
                (cancelStr || confirmStr) && (
                    <DialogActions>
                        <Button onClick={handlerCancel} color="primary">
                            {cancelStr}
                        </Button>
                        <Button disabled={isDisableConfirm}
                                loading={loading}
                                onClick={handlerConfirm}
                                color="primary">
                            {confirmStr}
                        </Button>
                    </DialogActions>
                )
            }
        </Dialog>
    );
}
