import React, { useRef, useEffect, useState } from 'react';
import {Tooltip, Typography} from '@material-ui/core';
const OverflowTip = ({text}) => {
    // Create Ref
    const textElementRef = useRef();

    const compareSize = () => {
        const compare =
            textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
        setHover(compare);
    };

    // compare once and add resize listener on "componentDidMount"
    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
    }, []);

    // remove resize listener again on "componentWillUnmount"
    useEffect(() => () => {
        window.removeEventListener('resize', compareSize);
    }, []);

    // Define state and function to update the value
    const [hoverStatus, setHover] = useState(false);

    return (
        <Tooltip
            title={text}
            interactive
            disableHoverListener={!hoverStatus}
        >
            <Typography
                variant={ 'h4' }
                ref={textElementRef}
                noWrap={true}
            >
                {text}
            </Typography>
        </Tooltip>
    );
};

export default OverflowTip;