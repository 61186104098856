import {SET_PROJECT_TYPES} from "../actions/projectTypes";

const initialState = {
    items: []
};

export default function projects(state = initialState, action) {
    switch (action.type) {
        case SET_PROJECT_TYPES:
            return {
                items: action.data
            }
        default:
            return state;
    }
}
