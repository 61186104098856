import React, {useEffect} from "react";
import {useSpring, animated} from "react-spring";


const hideStyle = (spring: number) => ({
    opacity: 0
});

const showStyle = {
    opacity: 1
}

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>{
    spring?: number,
    show?: boolean,
    rerenderPoint?: any
}

const SpringOpacity: React.FC<Props> = (props) => {


    const t = {
        spring: 200,
        show: true,
        ...props
    };


    const {spring, show, rerenderPoint, children, ...other} = t;
    const [style, set] = useSpring(() => hideStyle(spring))

    useEffect(() => {

        set(show ? showStyle : hideStyle(spring));

    }, [set, show, spring]);

    return (
        //@ts-ignores
        <animated.div style={style} {...other}>
            {
                show && children
            }
        </animated.div>
    )
}

export default SpringOpacity;
