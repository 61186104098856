import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {useDispatch, useSelector} from "react-redux";
import {hideSnackbar} from "../../redux/actions/snackbar";



const TransitionsSnackbar = () => {
    //@ts-ignore
    const {open, text, severity} = useSelector(store => store.snackbar);

    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(hideSnackbar());
    };

    return (
        <Snackbar
            open={open}
            onClose={handleClose}
            autoHideDuration={6000}
        >
            <MuiAlert elevation={6}
                      variant="filled"
                      onClose={handleClose}
                      severity={severity}>
                {text}
            </MuiAlert>
        </Snackbar>
    );
}


export default TransitionsSnackbar;
