import { SET_EXPERIMENT_ID, SET_EXPERIMENT_BASE_INFO, SET_EXPERIMENT_REVENUES, SET_EXPERIMENT_IMPRESSIONS, SET_EXPERIMENT_FUNNEL, SET_EXPERIMENT_METRICS } from "../actions/experiment";

const boostrapModel = {
    isResponseServer: false,
    amount: {
        acontrol: 0,
        amount1: 0,
        amount1Sum: 0,
        amount2: 0,
        amount2Sum: 0,
        average: 0,
        bcontrol: 0,
        mean1: 0,
        mean2: 0
    },
    amountRaw: {
        acontrol: 0,
        amount1: [],
        amount1Sum: 0,
        amount2: [],
        amount2Sum: 0,
        average: 0,
        bcontrol: 0,
        mean1: 0,
        mean2: 0
    },
    bins: {
        control: {
            values: [],
            thresholds: []
        },
        variant: {
            values: [],
            thresholds: []
        }
    },
    bootstrap: {
        _df: 0,
        _dist: { _df: 0, _pdf_const: 0 },
        _mean: 0,
        _options: { mu: 0, varEqual: false, alpha: 0.1, alternative: 0 },
        _se: 0
    },
    confidence: [],
    freedom: 0,
    p_value: 0,
    result: "no data",
    testValue: 0,
    valid: true
}

const initialState = {
    createAt: null,
    stopAt: null,
    name: null,
    experimentId: null,
    projectId: null,
    description: null,
    parameters: null,
    audience: null,
    arppu: boostrapModel,
    isResponseServerFunnel: false,
    funnel: [],
    impressions: boostrapModel,
    lifeTimeUsers: boostrapModel,
    lifeTimeUsersSums: {
        totalUsersControl: 0,
        totalUsersVariant: 0
    },
    payConversion: {
        controlUsers: 0,
        difference: 0,
        improvement: 0,
        p_value: 0,
        involvedControlUsers: 0,
        involvedVariantUsers: 0,
        percentageControl: 0,
        percentageVariant: 0,
        result: "no data",
        variantUsers: 0
    },
    subConversion: {
        controlUsers: 0,
        difference: 0,
        improvement: 0,
        p_value: 0,
        involvedControlUsers: 0,
        involvedVariantUsers: 0,
        percentageControl: 0,
        percentageVariant: 0,
        result: "no data",
        variantUsers: 0
    },
    conversionMetrics: {
        isResponseServer: false,
        categories: {}
    },
    numericMetrics: {
        isResponseServer: false,
        categories: {}
    },
    retentionTracking: [],
    revenues: boostrapModel,
    totalArppuUsers: [],
    users: [],
    lastUpdatedAt: new Date(),
    progressValues:{
        inapps: {},
        impressions: {}
    }
};

export default function test(state = initialState, action) {
    switch (action.type) {
        case SET_EXPERIMENT_ID:
            if (action.data === state.experimentId) {
                return state;
            }
            return {
                ...initialState,
                lastUpdatedAt: new Date(),
                experimentId: action.data,
            }
        case SET_EXPERIMENT_BASE_INFO: {
            return {
                ...state,
                ...action.data
            }
        }
        case SET_EXPERIMENT_REVENUES: {
            const {
                revenues,
                arppu,
                users,
                totalUsers,
                payConversion,
                subConversion,
                retentionTracking,
                lifeTimeUsers,
                totalArppuUsers,
                lifeTimeUsersSums,
                lastUpdatedAt,
                progressValues
            } = action.data;

            const t = new Date(lastUpdatedAt);

            revenues['isResponseServer'] = true;
            arppu['isResponseServer'] = true;
            users['isResponseServer'] = true;
            totalUsers['isResponseServer'] = true;
            payConversion['isResponseServer'] = true;
            subConversion['isResponseServer'] = true;
            lifeTimeUsers['isResponseServer'] = true;

            return {
                ...state,
                revenues,
                arppu,
                users,
                totalUsers,
                payConversion,
                subConversion,
                retentionTracking,
                lifeTimeUsers,
                totalArppuUsers,
                lifeTimeUsersSums,
                lastUpdatedAt: state.lastUpdatedAt > t ? t : state.lastUpdatedAt,
                progressValues: progressValues || {}
            }
        }
        case SET_EXPERIMENT_IMPRESSIONS: {
            console.log(action);
            const t = new Date(action.data.lastUpdatedAt);
            return {
                ...state,
                impressions: {
                    isResponseServer: true,
                    ...action.data.impressions
                },
                lastUpdatedAt: state.lastUpdatedAt > t ? t : state.lastUpdatedAt
            }
        }
        case SET_EXPERIMENT_FUNNEL: {
            return {
                ...state,
                isResponseServerFunnel: true,
                funnel: action.data.funnel
            }
        }
        case SET_EXPERIMENT_METRICS: {
            return {
                ...state,
                numericMetrics: { isResponseServer: true, categories: action.data.numericMetrics },
                conversionMetrics: { isResponseServer: true, categories: action.data.conversionMetrics },
            }
        }
        default:
            return state;
    }
}
