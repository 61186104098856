
import { GeneralResponse, request } from "../../api";
import { IGetMeRequest, IGetMeResponse, ISignInRequest, ISignInResponse } from "./requestsResponses";

export const signIn = async (params: ISignInRequest): Promise<GeneralResponse<ISignInResponse>> => {
    const res =  await request<ISignInRequest, ISignInResponse>('accounts/login', params, {
        requestId: 'signIn'
    }).post();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}


export const getMe = async (params: IGetMeRequest): Promise<GeneralResponse<IGetMeResponse>> => {
    const res =  await request<IGetMeRequest, IGetMeResponse>('accounts/profile', params, {
        requestId: 'getMe'
    }).get();
    if(res.status === 200){
        return {
            err: false,
            result: res.data
        }
    }else{
        return {
            err: true
        }
    }
}
