import {SET_TESTS, SET_DISABLED, SET_ACTIVES} from "../actions/tests";

const initialState = {
    actives: [],
    disabled: []
};

export default function test(state = initialState, action) {
    switch (action.type) {
        case SET_TESTS:
            return {
                actives: action.data.actives,
                disabled: action.data.disabled
            }
        case SET_DISABLED:
            return {
                ...state,
                disabled: action.data
            }
        case SET_ACTIVES: {
            return {
                ...state,
                actives: action.data
            }
        }
        default:
            return state;
    }
}
