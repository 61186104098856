import { action, computed, makeAutoObservable, runInAction } from "mobx"
import { ExperimentResult, IExperiment, InAppFilterValue, TestVariant } from "../../../services/experiments/models";
import { getExperiment, getFunnel, getImpressions, getMetrics, getRevenues } from "../../../services/experiments";
import {
    IGetFunnelResponse,
    IGetImpressionsResponse,
    IGetMetricResponse,
    IGetRevenuesResponse
} from "../../../services/experiments/requestResponses";
import Utils from "../../../utils/Utils";

const getUsersByTestVariant = (arr: {
    test_variant: TestVariant,
    amount: number
}[], variant: TestVariant) => {
    if (!arr) {
        return 0;
    }
    const find = arr.find(i => i.test_variant === variant);
    if (find) {
        return find.amount;
    }
    return 0;
}


class ExperimentsStore {
    isFirstLoad: boolean = true;
    filters: {
        funnel: string,
        inapps: InAppFilterValue,
        forceRecalculate: boolean,
        requestParam: string,
        excludeFunnel: boolean,
        funnelType: string,
    } = {
        funnel: "",
        inapps: InAppFilterValue.All,
        forceRecalculate: true,
        requestParam: "",
        excludeFunnel: false,
        funnelType: 'default',

    };
    currentExperiment?: IExperiment;
    impressions?: IGetImpressionsResponse;
    revenues?: IGetRevenuesResponse;
    metrics?: IGetMetricResponse;
    funnel?: IGetFunnelResponse;
    isLoading: boolean = false;

    constructor() {
        makeAutoObservable(this)
    }

    @action
    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @action
    setFilterFunnel(funnel: string) {
        this.filters.funnel = funnel;
    }

    @action
    setFilterInapps(inapps: InAppFilterValue) {
        this.filters.inapps = inapps;
    }

    @action
    setFilterRequestParam(params: string) {
        this.filters.requestParam = params;
    }

    @action
    setFilterFunnelType(funnelType: boolean) {
        this.filters.excludeFunnel = funnelType;
    }

    @action
    setFilterFunnelEventType(funnelType: string) {
        this.filters.funnelType = funnelType;
    }

    @action
    setFiltersForceRecalculate(forceReload: boolean){
        this.filters.forceRecalculate = forceReload;
    }

    async loadExperiment(id: string){

        const res = await getExperiment(id);
        if(!res.err){
            runInAction(() => {
                this.currentExperiment = res.result;
            })
        }
    }

    async ensureExperimentLoaded(id: string){
        if(!this.currentExperiment || this.currentExperiment.id.toString() !== id){
            runInAction(() => {
                this.revenues = undefined;
                this.impressions = undefined;
                this.funnel = undefined;
                this.metrics = undefined;
            })
            await this.loadExperiment(id);
        }
    }

    async reloadExperiment(){
        runInAction(() => {
            this.currentExperiment = undefined;
        })
    }

    async loadRevenues(){
        if(!this.currentExperiment) return;
        runInAction(() => {
            this.revenues = undefined;
        })
        const experimentId = this.currentExperiment.id;
        const res = await getRevenues(experimentId.toString(), {
                ...this.filters,
                funnel: this.isFirstLoad ? "" : this.filters.funnel
            }
        );
        if(!res.err){
            runInAction(() => {
                this.revenues = res.result;
            })
        }
    }

    async loadImpressions(){
        if(!this.currentExperiment) return;
        runInAction(() => {
            this.impressions = undefined;
        })
        const experimentId = this.currentExperiment.id;
        const res = await getImpressions(experimentId.toString(), {
            ...this.filters,
            funnel: this.isFirstLoad ? "" : this.filters.funnel
        });
        if(!res.err){
            runInAction(() => {
                this.impressions = res.result;
            })
        }
    }

    async loadFunnel(){

        if(!this.currentExperiment) return;
        runInAction(() => {
            this.funnel = undefined;
        })
        const experimentId = this.currentExperiment.id;
        const res = await getFunnel(experimentId.toString(), {
            ...this.filters,
            funnel: this.isFirstLoad ? "" : this.filters.funnel
        });
        if(!res.err){
            runInAction(() => {
                this.funnel = res.result;
            })
        }
    }

    async loadMetrics(){
        if(!this.currentExperiment) return;
        runInAction(() => {
            this.metrics = undefined;
        })
        const experimentId = this.currentExperiment.id;
        const res = await getMetrics(experimentId.toString(), {
            ...this.filters,
            funnel: this.isFirstLoad ? "" : this.filters.funnel
        });
        if(!res.err){
            runInAction(() => {
                this.metrics = res.result;
            })
        }
    }

    async loadExperimentResults(){
        this.setIsLoading(true)
        await Promise.all([
            this.loadRevenues(),
            this.loadImpressions(),
            this.loadFunnel(),
            this.loadMetrics()
        ])
        this.setIsLoading(false)
        this.setIsFirstLoad(false);
    };

    @action
    setIsFirstLoad(value: boolean) {
        this.isFirstLoad = value;
    }



    @computed
    getRevenuesControlSummary(){
        const revenues = this.revenues;
        if(!revenues) return null;
        const impressions = this.impressions;
        if(!impressions) return null;
        const arppu = revenues.arppu;

        const totalUsersControl = revenues.users.control;
        const arppuUsersA = revenues.totalArppuUsers.control;
        const lifeTimeControl = (revenues.lifeTimeUsers.amount.amount1Sum / totalUsersControl);
        const impressionAverageControl = totalUsersControl > 0 ?
            (impressions.impressions.amount.amount1Sum / totalUsersControl).toFixed(2) : "0.00";


        const control = {
            mean: revenues.revenues.amount.mean1,
            users: totalUsersControl,
            amount: Utils.r2(revenues.revenues.amount.amount1Sum),
            average: totalUsersControl === 0 ? '0.00' : (revenues.revenues.amount.amount1Sum / totalUsersControl).toFixed(2),
            averageARPPU: arppuUsersA === 0 ? '0.00' : (arppu.amount.amount1Sum / arppuUsersA).toFixed(2),
            diff: Utils.r2(revenues.revenues.amount.mean1 - revenues.revenues.amount.average),
            impressions: impressions.impressions.amount.amount1Sum,
            impaverage: impressionAverageControl,
            lifeTime: isNaN(lifeTimeControl) || lifeTimeControl === Infinity ? 0 : (lifeTimeControl/3600).toFixed(0),
            percentPay: revenues.payConversion.control || 0
        }
        return control;
    }


    @computed
    getRevenuesVariantSummary(){
        const revenues = this.revenues;
        if(!revenues) return null;
        const impressions = this.impressions;
        if(!impressions) return null;
        const arppu = revenues.arppu;

        const totalUsersVariant = revenues.users.variant;
        const arppuUsersA = revenues.totalArppuUsers.variant;
        const lifeTimeVariant = (revenues.lifeTimeUsers.amount.amount2Sum / totalUsersVariant);
        const impressionAverageControl = totalUsersVariant > 0 ?
            (impressions.impressions.amount.amount2Sum / totalUsersVariant).toFixed(2) : "0.00";


        const variant = {
            mean: revenues.revenues.amount.mean2,
            users: totalUsersVariant,
            amount: Utils.r2(revenues.revenues.amount.amount2Sum),
            average: totalUsersVariant === 0 ? '0.00' : (revenues.revenues.amount.amount2Sum / totalUsersVariant).toFixed(2),
            averageARPPU: arppuUsersA === 0 ? '0.00' : (arppu.amount.amount2Sum / arppuUsersA).toFixed(2),
            diff: Utils.r2(revenues.revenues.amount.mean2 - revenues.revenues.amount.average),
            impressions: impressions.impressions.amount.amount2Sum,
            impaverage: impressionAverageControl,
            lifeTime: isNaN(lifeTimeVariant) || lifeTimeVariant === Infinity ? 0 : (lifeTimeVariant/3600).toFixed(0),
            percentPay: revenues.payConversion.variant || 0
        }
        return variant;
    }

    @computed
    getImpressionsControlSummary(){
        const impressions = this.impressions;
        if(!impressions) return null;

        const control = {
            mean: impressions.impressions.amount.mean1,
            impressions: impressions.impressions.amount.amount1Sum,
            impaverage: impressions.impressions.amount.mean1.toFixed(2),
        }
        return control;
    }

    @computed
    getImpressionsVariantSummary(){
        const impressions = this.impressions;
        if(!impressions) return null;

        const variant = {
            mean: impressions.impressions.amount.mean2,
            impressions: impressions.impressions.amount.amount2Sum,
            impaverage: impressions.impressions.amount.mean2.toFixed(2),
        }
        return variant;
    }


    @computed
    getTotalUsers(){
        const revenues = this.revenues;
        if(!revenues) return 0;
        return revenues.users.control + revenues.users.variant;
    }


    @computed
    getExperimentResult(){
        const revenues = this.revenues;
        const impressions = this.impressions;
        if(!revenues || !impressions) return null;
        let results = [
            revenues.revenues.result,
            impressions.impressions.result,
            revenues.arppu.result,
            revenues.payConversion.result,
            revenues.lifeTimeUsers.result
        ]

        results = results.concat(
            revenues.retentionTracking.map(r => r.value.result)
        );




        const successLength = results.filter(v => v === ExperimentResult.success);
        const failLength = results.filter(v => v === ExperimentResult.failed);

        return {
            successLength,
            failLength
        }


    }
}

export default ExperimentsStore;
